<template>
  <template v-if="!loading">
    <template v-if="ssResult && ssResult.page">
      <SeverityLine
        type="line"
        :noMaxWidth="true"
        :showKey="true"
        :data="[
          {
            key: 'Fail',
            value: ssResult.page.rows.filter( r => getCellStatus( r.columns[0].cell ).toLowerCase() == 'fail' ).length,
          },
          {
            key: 'Advisory',
            value: ssResult.page.rows.filter( r => getCellStatus( r.columns[0].cell ).toLowerCase() == 'advisory' ).length,
          },
          {
            key: 'Pass',
            value: ssResult.page.rows.filter( r => getCellStatus( r.columns[0].cell ).toLowerCase() == 'pass' ).length,
          },
          {
            key: 'Not Applicable',
            show: !hasPermission('SimpleMode', 'NoNotApplicable'),
            value: ssResult.page.rows.filter( r => getCellStatus( r.columns[0].cell ).toLowerCase() == 'not applicable' ).length,
          },
          {
            key: 'Untested',
            value: ssResult.page.rows.filter( r => getCellStatus( r.columns[0].cell ).toLowerCase() != 'fail'
              && getCellStatus( r.columns[0].cell ).toLowerCase() != 'advisory'
              && getCellStatus( r.columns[0].cell ).toLowerCase() != 'pass'
              && getCellStatus( r.columns[0].cell ).toLowerCase() != 'not applicable' ).length - 1,
          }
        ]" />
      <div class="Checks">
        <FilterBar ref="filterBar" class="Checks_Filters" @clear="clearAllFilters" :filters="filters" :border="false">
          <FilterOpt :title="$gettext('Check Status')" @update="(v) => { console.log( v ); filters.status = v }"
            @open="(current) => { filterBar.setCurrent(current) }" :options="filterOpts" />
            <FilterSort
            @input="v => sort = v"
            :options="{
              'default': 'Default (WCAG)',
              'latest': 'Last tested (most recent first)',
              'oldest': 'Last tested (oldest first)',
            }"
          />
        </FilterBar>
        <div class="Checks_Selected" v-if="rowsSelected">
          <div class="Checks_Selected_Count">{{ rowsSelected }}</div>
          <div class="Checks_Selected_Opts">
            <span>Mark checks as:</span>
            <button class="Checks_Selected_Opts_Button" @click="setSelectedStatus('Pass')"><Pill text="Pass" size="small" /></button>
            <button class="Checks_Selected_Opts_Button" @click="setSelectedStatus('Fail')"><Pill text="Fail" size="small" /></button>
            <button class="Checks_Selected_Opts_Button" @click="setSelectedStatus('Advisory')"><Pill text="Advisory" size="small" /></button>
            <button v-if="!hasPermission('SimpleMode', 'NoNotApplicable')" class="Checks_Selected_Opts_Button" @click="setSelectedStatus('Not Applicable')">
              <Pill text="Not Applicable" size="small" />
            </button>
            <button v-if="!hasPermission('SimpleMode', 'NoTodo')" class="Checks_Selected_Opts_Button" @click="setSelectedStatus('Todo')">
              <Pill text="Todo" size="small" />
            </button>
          </div>
          <Button class="Checks_Selected_Cancel" type="transparent" size="small" @click="deselectAll()">Cancel</Button>
        </div>
        <template v-for="row in sortedRows" :key="row.template.ref">
          <div class="Checks_SectionHead" v-if="row.newSection && sort == 'default'">
            <!-- <input type="checkbox" :aria-labelledby="`checkhead-${row.template.section.replace( ' ', '' )}-label`" /> -->
            <span role="checkbox" :aria-checked="sectionStatus( row.template.section )" @click="sectionToggle( row.template.section)" :aria-labelledby="`checkhead-${row.template.section.replace( ' ', '' )}-label`" tabindex="0">
              <Icon type="regular" :icon="sectionIcon( row.template.section )" />
            </span>
            <h3 :id="`checkhead-${row.template.section.replace( ' ', '' )}-label`" class="Checks_SectionHead_Title"><span>{{ row.template.section }}</span></h3>
          </div>
          <div :class="['Checks_Item', row.checked?'_checked':0, row.preselect?'_hover':'']"
            v-if="row.template.ref != '0'
              && ( filters.status?.length == 0 || filters.status?.indexOf( getCellStatus( row.columns[0].cell ).toLowerCase().replace( ' ', '-' ) ) >= 0 )"
            @click="( e ) => selectCheck( e, row )"
            @mouseover="( e ) => hoverRow( e, row )"
            @mouseleave="( e ) => unhoverRow( e, row )">
            <div class="Checks_Item_Title">
              <div class="Checks_Item_Title_Select">
                <!-- <input type="checkbox" :aria-labelledby="`check-${row.template.ref}-label`"/> -->
                <span role="checkbox" :aria-checked="row.checked ? 'true' : 'false'" :aria-labelledby="`check-${row.template.ref}-label`" tabindex="0" @click.prevent.stop="() => { row.checked = !row.checked }">
                  <Icon type="regular" :icon="row.checked ? 'square-check' : 'square'" />
                </span>
              </div>
              <a :id="`check-${row.template.ref}-label`" href="#" role="button" class="Checks_Item_Title_Link" @click.stop.prevent="showCell( row )">
                <span class="Checks_Item_Title_Link_Ref">{{ row.template.ref }}</span>&nbsp;
                <span class="Checks_Item_Title_Link_Header">{{ row.template.header }}</span>&nbsp;
                <span class="Checks_Item_Title_Link_Level">{{ row.template.level }}</span>
              </a>
            </div>
            <div class="Checks_Item_Status">
              <div class="Checks_Item_Status_Top">
                <TooltipIcon v-if="getCellStatus( row.columns[0].cell )=='Fail'&&row.columns[0].cell.items.filter( i => i.instance != '' ).length==0" severity="serious" type="solid" icon="triangle-exclamation" text="This failed check requires documentation of the issues that caused the failure. No issues have been added yet. Please add them to proceed." />
                &nbsp;
                <Pill v-if="!hasPermission( 'QualityOfLife', 'QuickCheck' )" :text="getCellStatus( row.columns[0].cell )" size="small" :icon="row.columns[0].cell.items[0].instance=='ml'?['ml']:false" />
                <Pill v-else
                  tabindex="0"
                  :text="getCellStatus( row.columns[0].cell )"
                  size="small" :icon="row.columns[0].cell.items[0].instance=='ml'?['ml']:false"
                  @mouseenter="quickCheckOpen( row )"
                  @mouseleave="quickCheckClose( row )"
                  @focus="quickCheckOpen( row )"
                  @blur="quickCheckClose( row )"

                  aria-haspopup="true"
                  :aria-expanded="row.columns[0].cell.showOpts"
                  :aria-controls="`quickcheck-${row.template.ref}`"
                  :id="`pill-${row.template.ref}`"
                  />
                <div class="Checks_Item_Status_Top_Dropdown"
                  v-if="hasPermission( 'QualityOfLife', 'QuickCheck' )&&row.columns[0].cell.showOpts"
                  :id="`quickcheck-${row.template.ref}`"
                  @mouseenter="quickCheckOpen( row, true )"
                  @mouseleave="quickCheckClose( row, true )"
                  @keydown.tab="quickCheckClose( row )"
                  >
                  <div class="Checks_Item_Status_Top_Dropdown_Inner" v-if="getCellStatus( row.columns[0].cell )!='untested' && isBefore( new Date( row.columns[0].cell.updated ), subYears( new Date(), 1 ) )">
                    <h4>Check outcome unchanged?</h4>
                    <button class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.prevent="setStatusOn( row.columns[0].cell, getCellStatus( row.columns[0].cell ) )"><Pill :text="`Confirm ${getCellStatus( row.columns[0].cell )}`" :type="getCellStatus( row.columns[0].cell ).replace( ' ', '' ).toLowerCase()" width="full" /></button>
                    <p>Confirms the result and updates the last reviewed timestamp for this check.</p>
                    
                    <h4>Check outcome changed?</h4>
                    <button v-if="getCellStatus( row.columns[0].cell )!='Pass'" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Pass' )"><Pill text="Pass" width="full" /></button>
                    <button v-if="getCellStatus( row.columns[0].cell )!='Fail'" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Fail' )"><Pill text="Fail" width="full" /></button>
                    <button v-if="getCellStatus( row.columns[0].cell )!='Advisory'" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Advisory' )"><Pill text="Advisory" width="full" /></button>
                    <button v-if="!hasPermission( 'SimpleMode', 'NoNotApplicable' ) && getCellStatus( row.columns[0].cell )!='Not Applicable'" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Not Applicable' )">
                      <Pill text="Not Applicable" width="full" />
                    </button>
                    <button v-if="!hasPermission( 'SimpleMode', 'NoTodo' ) && getCellStatus( row.columns[0].cell )!='Todo'" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Todo' )">
                      <Pill text="Todo" width="full" />
                    </button>
                  </div>
                  <div class="Checks_Item_Status_Top_Dropdown_Inner" v-else>
                    <h4>Choose an outcome:</h4>
                    <button class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Pass' )"><Pill text="Pass" width="full" /></button>
                    <button class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Fail' )"><Pill text="Fail" width="full" /></button>
                    <button class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Advisory' )"><Pill text="Advisory" width="full" /></button>
                    <button v-if="!hasPermission( 'SimpleMode', 'NoNotApplicable' )" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Not Applicable' )">
                      <Pill text="Not Applicable" width="full" />
                    </button>
                    <button v-if="!hasPermission( 'SimpleMode', 'NoTodo' )" class="Checks_Item_Status_Top_Dropdown_Inner_Button" @click.stop.prevent="setStatusOn( row.columns[0].cell, 'Todo' )">
                      <Pill text="Todo" width="full" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="Checks_Item_Status_LastChecked" v-if="row.columns[0].cell.updated">
                <Icon type="solid" icon="rotate" :spin="row.columns[0].cell.saving?true:false"/>
                <span v-if="row.columns[0].cell.saving">saving...</span>
                <span v-else :key="`${row.template.ref}-time-${ticker}`">{{ formatDistance( new Date( row.columns[0].cell.updated ), new Date(), { addSuffix: true }) }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <IssueInstanceModal ref="modal" :report="reportVersion" @success="item => addItem( item.identifier )"/>
      <IssueInstanceViewModal ref="issueviewmodal" @resolved="resolvedFromModal" @closed="modalClosed" @mutated="mutateIssue" />
      <AddExistingCheckItemModal ref="addexistingmodal" @addItem="item => addItem( item )"/>
    </template>
    <template v-else>
      <div class="AddSheet">
        <p>To start testing you need to setup a checklist to track progress and locate issues. We have a number of pre-made templates to help you get to testing without having to remember the exact WCAG references.</p>
        <div class="AddSheet_Options" v-if="templates">
          <div class="AddSheet_Options_Option" v-for="(template, tid) in templates" :key="tid">
            <h3>{{ template }}</h3>
            <p>{{ templateDescs[tid] }}</p>
            <Button size="small" type="primary" @click="useSheet(tid)">{{$gettext('Use this one')}}</Button>
          </div>
        </div>

        <!-- <template v-if="previousSheetsFiltered.length">
          <p v-translate>or import a previous page</p>

          <div class="AddSheet_Options">
            <template v-for="page in previousSheetsFiltered" :key="page._id">
              <div class="AddSheet_Options_Option">
                <h3>{{ page.title }} {{$gettext('from version')}} {{ page.reportVersion.version }}</h3>
                <p>{{$gettext('Template:')}} {{ page.template.name }}</p>
                <Button size="small" type="primary" @click="importSheet(page._id)">{{$gettext('Import this one')}}</Button>
              </div>
            </template>
          </div>
        </template> -->
      </div>
    </template>
  </template>
  <Loader v-else />

  <SlidePanel ref="slidepanel" :title="sidePanelTitle" class="CheckCell" @close="resetCheck()">
    <template v-if="currentRow">
      <p class="CheckCell_Description">
        <Icon type="solid" icon="circle-info" />
        <span v-if="currentRow.template.criterion?.description">{{ currentRow.template.criterion.description }}</span>
      </p>
      <a class="CheckCell_GuidanceBtn" href="#" role="button" @click="showGuidance( currentRow.template.details, `${currentRow.template.section} - ${currentRow.template.header}` )">
        <Icon type="solid" icon="life-ring" />&nbsp;
        <span v-if="!guidanceOpen" v-translate>Show guidance for this check</span>
        <span v-else v-translate>Hide guidance for this check</span>
      </a>
      
      <div class="CheckCell_StillCorrect" v-if="cellStatus!='untested' && isBefore( new Date( currentCell.updated ), subYears( new Date(), 1 ) )">
        <div class="CheckCell_StillCorrect_Top">
          <h3 class="CheckCell_StillCorrect_Top_Heading">Current check outcome</h3>
          <span class="CheckCell_StillCorrect_Top_LastChecked">
            <Icon type="solid" icon="rotate" :spin="currentCell.saving?true:false"/>
            {{ formatDistance( new Date( currentCell.updated ), new Date(), { addSuffix: true }) }}
          </span>
        </div>
        <Pill size="small" :text="cellStatus" />
        <Button
          @mouseenter="tooltipEl?.showTooltip()"
          @mouseleave="tooltipEl?.hideTooltip()"
          @focus="tooltipEl?.showTooltip()"
          @blur="tooltipEl?.hideTooltip()"
          type="link-bold"
          size="small"
          @click="setStatus( cellStatus )">This outcome is still correct</Button>
        <Tooltip ref="tooltipEl" text="Clicking here confirms the check outcome is still the same as it was when this check was last conducted and sets the 'Last updated' time stamp for this check to the current time."/>
      </div>
      <div class="CheckCell_Outcome">
        <template v-if="true">
          <h3>Change the check outcome</h3>
          <h4>Please select a new outcome for this check:</h4>
        </template>
        <h3 v-else>Select an outcome for this check:</h3>
        <div class="CheckCell_Outcome_Opts">
          <button class="CheckCell_Outcome_Opts_Button" @click="setStatus('Pass')" :aria-checked="cellStatus.toLowerCase()=='pass'"><Pill :checkable="true" :checked="cellStatus.toLowerCase()=='pass'" text="Pass" size="small" /></button>
          <button class="CheckCell_Outcome_Opts_Button" @click="setStatus('Fail')" :aria-checked="cellStatus.toLowerCase()=='fail'"><Pill :checkable="true" :checked="cellStatus.toLowerCase()=='fail'" text="Fail" size="small" /></button>
          <button class="CheckCell_Outcome_Opts_Button" @click="setStatus('Advisory')" :aria-checked="cellStatus.toLowerCase()=='advisory'"><Pill :checkable="true" :checked="cellStatus.toLowerCase()=='advisory'" text="Advisory" size="small" /></button>
          <button v-if="!hasPermission('SimpleMode', 'NoNotApplicable')" class="CheckCell_Outcome_Opts_Button" @click="setStatus('Not Applicable')" :aria-checked="cellStatus.toLowerCase()=='not applicable'"><Pill :checkable="true" :checked="cellStatus.toLowerCase()=='not applicable'" text="Not Applicable" size="small" /></button>
          <button v-if="!hasPermission('SimpleMode', 'NoTodo')" class="CheckCell_Outcome_Opts_Button" @click="setStatus('Todo')" :aria-checked="cellStatus.toLowerCase()=='todo'"><Pill :checkable="true" :checked="cellStatus.toLowerCase()=='todo'" text="Todo" size="small" /></button>
        </div>
      </div>
      
      <Toggle />
      <div class="CheckCell_Issues">
        <h3 v-translate>Issue(s) that cause this check to fail:</h3>
        <p v-if="editable && ( cellStatus == 'Fail' || cellStatus == 'Advisory' )" v-translate>Capture details of each individual issue on this page causing this check to fail.</p>

        <span class="CheckCell_Issues_Btns" >
          <template v-if="editable && ( cellStatus == 'Fail' || cellStatus == 'Advisory' )">
            <Button type="border" size="micro" @click="openModal( currentCell.row )">Add new issue</Button>
            <span v-if="type == 'page'">or</span>
            <Button v-if="type == 'page'" type="border" size="micro" @click="addexistingmodal.show( currentCell.row, currentCell.column )">Add existing issue</Button>
          </template>
          <template v-if="!hasPermission( 'SimpleMode', 'NoNotes')">
            <span v-if="editable && ( cellStatus == 'Fail' || cellStatus == 'Advisory' )">or</span>
            <Button type="border" size="micro" @click="addNote()">Add note</Button>
          </template>
        </span>

        <div class="CheckCell_Issues_List">
          <template v-for="( item, index ) in tmpCell.items" :key="`item-${item.instance}`">
            <template v-if="( index != 0 || cellStatus == 'empty' )">
              <div class="CheckCell_Issues_List_Item" v-if="item.instance && item.instance.trim() != ''">
                <div class="CheckCell_Issues_List_Item_Head">
                  <div class="CheckCell_Issues_List_Item_Head_Left">
                    <Icon
                      :class="[
                        issues[ item.instance ]?.status.indexOf('closed')==0 ? '_closed' : '',
                        currentCell.type == 'page' && issues[ item.instance ]?.page?._id != currentCell.column && issues[ item.instance ]?.others?.map( o => o.page._id ).indexOf( currentCell.column ) == -1 ? '_notonpage': ''
                      ]"
                      type="solid"
                      icon="exclamation-triangle"
                    />
                    <span class="CheckCell_Issues_List_Item_Head_Left_Additional" v-if="issues[ item.instance ]?.status.indexOf('closed')==0">
                      Issue Closed
                    </span>
                    <template v-else-if="currentCell.type == 'page' && issues[ item.instance ]?.page?._id != currentCell.column && issues[ item.instance ]?.others?.map( o => o.page._id ).indexOf( currentCell.column ) == -1">
                      <Button size="micro" type="border" @click="addIssueToPage( item.instance )" >Add to page</Button>
                    </template>
                    <!-- <span class="CheckCell_Issues_List_Item_Head_Left_Additional"  v-else-if="currentCell.type == 'page' && issues[ item.instance ]?.page?._id != currentCell.column && issues[ item.instance ]?.others?.map( o => o.page._id ).indexOf( currentCell.column ) == -1">
                      Issue not on page
                      <button @click="addIssueToPage( item.instance )">Add it</button>
                    </span> -->
                  </div>
                  <div class="CheckCell_Issues_List_Item_Head_Right" v-if="issues[ item.instance ]">
                    <span>Priority</span>&nbsp;
                    <Pill
                      :text="[ 'Advisory', 'Low', 'Medium', 'High', 'Critical' ][ issues[ item.instance ]?.priority ]"
                      :tooltip="[
                        'Issues that should be addressed to improve user experience',
                        'Issues that may have minor impact for some users',
                        'Issues that some users may have difficult with',
                        'Issues that cause severe difficulties for some users',
                        'Issues that mean some users cannot bypass without assistance'
                      ][ issues[ item.instance ]?.priority ]"
                      size="small" />&nbsp;
                    <span>Severity</span>&nbsp;
                    <Pill :text="[ 'Lowest', 'Low', 'Medium', 'High', 'Highest' ][ issues[ item.instance ]?.template.severity ]" size="small" />&nbsp;
                    <ButtonSet class="CheckCell_Issues_List_Item_Head_Right_Menu" label="Options" :dropdown="true" type="icon" :icon="['solid', 'ellipsis-vertical']">
                      <Button size="micro" type="transparent" @click.prevent="issueviewmodal.show( item.instance, true )">{{$gettext('Edit Issue')}}</Button>
                      <Button size="micro" type="transparent" @click="editNote( item, index )">{{$gettext('Edit Note')}}</Button>
                      <template v-if="issues[ item.instance ]?.status.indexOf('closed')==0">
                        <Button size="micro" type="transparent" @click="deleteNote( index )">{{$gettext('Delete item')}}</Button>
                      </template>
                      <Button v-else size="micro" type="transparent" @click="markResolved( item.instance, index )">{{$gettext('Mark as resolved')}}</Button>
                    </ButtonSet>
                  </div>
                </div>
                <div class="CheckCell_Issues_List_Item_Body">
                  <dl>
                    <div>
                      <dt>Issue Template:</dt>
                      <dd>{{ issues[ item.instance ]?.template.identifier }}: {{ issues[ item.instance ]?.template.title }}</dd>
                    </div>
                    <div>
                      <dt>Identifier:</dt>
                      <dd><router-link to="#" @click.prevent="issueviewmodal.show( item.instance )">{{ item.instance }}</router-link></dd>
                    </div>
                    <div class="CheckCell_Issues_List_Item_Body_Note" v-if="( item.text && item.instance && item.text.toLowerCase() != item.instance.toLowerCase() && item.text != issues[ item.instance ]?.reason ) || item.forceEdit">
                      <dt>Note:</dt>
                      <dd :id="`itemnote-${index}`" contenteditable tabindex="0" @input="( e ) => updateNote( item, e.target.innerText )">{{ item.text }}</dd>
                    </div>
                    <div>
                      <dt>Description:</dt>
                      <dd>{{ issues[ item.instance ]?.reason }}</dd>
                    </div>
                    <div>
                      <dt>Solution(s):</dt>
                      <dd>
                        <span v-if="issues[ item.instance ]?.solutions[0]">
                          {{ issues[ item.instance ]?.solutions[0].identifier }}: {{ issues[ item.instance ]?.solutions[0].title }}&nbsp;
                          <template v-if="issues[ item.instance ]?.solutions.length>1">(and {{ issues[ item.instance ]?.solutions.length-1 }} more)</template>
                        </span>
                        <span v-else>None given</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="CheckCell_Issues_List_Note" v-else>
                <Icon type="solid" icon="note-sticky" />
                <p :id="`itemnote-${index}`" contenteditable tabindex="0" @input="( e ) => updateNote( item, e.target.innerText )">{{ item.text }}</p>
                <ButtonSet class="CheckCell_Issues_List_Note_Menu" label="Options" :dropdown="true" type="icon" :icon="['solid', 'ellipsis-vertical']">
                  <Button size="micro" type="transparent" @click="editNote( item, index )">{{$gettext('Edit Note')}}</Button>
                  <Button size="micro" type="transparent" @click="deleteNote( index )">{{$gettext('Delete Note')}}</Button>
                </ButtonSet>
              </div>
            </template>
          </template>

          <div class="CheckCell_Issues_List_PotentialTitle" v-if="potentialIssues.length">
            <h5 class="">Related issues: <Pill :text="potentialIssues.length.toString()" size="small"/></h5>
            <p class="__fade">These are issues related to this check that are not currently associated.</p>
          </div>
          <template v-for="(issue, i) in potentialIssues" :key="`potentialIssue-${i}`">
            <div class="CheckCell_Issues_List_Item _potential">
              <div class="CheckCell_Issues_List_Item_Head">
                <div class="CheckCell_Issues_List_Item_Head_Left">
                  <Icon
                    class="_shouldbeonpage"
                    type="solid"
                    icon="exclamation-triangle"
                  />
                  <Button size="micro" type="border" @click="addItem( issue.identifier )">Add to cell</Button>
                </div>
                <div class="CheckCell_Issues_List_Item_Head_Right">
                  <span>Priority</span>&nbsp;
                  <Pill
                    :text="[ 'Advisory', 'Low', 'Medium', 'High', 'Critical' ][ issue.priority ]"
                    :tooltip="[
                      'Issues that should be addressed to improve user experience',
                      'Issues that may have minor impact for some users',
                      'Issues that some users may have difficult with',
                      'Issues that cause severe difficulties for some users',
                      'Issues that mean some users cannot bypass without assistance'
                    ][ issue.priority ]"
                    size="small" />&nbsp;
                  <span>Severity</span>&nbsp;
                  <Pill :text="[ 'Lowest', 'Low', 'Medium', 'High', 'Highest' ][ issue.template.severity ]" size="small" />&nbsp;
                  <ButtonSet class="CheckCell_Issues_List_Item_Head_Right_Menu" label="Options" :dropdown="true" type="icon" :icon="['solid', 'ellipsis-vertical']">
                    <Button size="micro" type="transparent" @click="addItem( issue.identifier )">{{$gettext('Add to cell')}}</Button>
                    <!-- <Button size="micro" type="transparent">{{$gettext('Remove from page')}}</Button> -->
                  </ButtonSet>
                </div>
              </div>
              <div class="CheckCell_Issues_List_Item_Body">
                <dl>
                  <div>
                    <dt>Issue Template:</dt>
                    <dd>{{ issue.template.identifier }}: {{ issue.template.title }}</dd>
                  </div>
                  <div>
                    <dt>Identifier:</dt>
                    <dd><router-link to="#" @click.prevent="issueviewmodal.show( issue.identifier )">{{ issue.identifier }}</router-link></dd>
                  </div>
                  <div>
                    <dt>Description:</dt>
                    <dd>{{ issue.reason }}</dd>
                  </div>
                  <div>
                    <dt>Solution(s):</dt>
                    <dd>
                      <span v-if="issue.solutions[0]">
                        {{ issue.solutions[0].identifier }}: {{ issue.solutions[0].title }}&nbsp;
                        <template v-if="issue.solutions.length>1">(and {{ issue.solutions.length-1 }} more)</template>
                      </span>
                      <span v-else>None given</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </SlidePanel>
  <PreviewPane ref="guidancePane" pretitle="Check guidance" :title="currentRow?`${currentRow.template.ref} ${currentRow.template.header }`:''" :show="guidanceOpen" @close="() => guidanceOpen = false">
    <iframe class="CheckCell_GuidanceFrame" v-if="guidanceType=='iframe'" :src="guidance" ref="guidanceIframe"></iframe>
    <template v-if="guidanceType=='markdown'">
      <vue-markdown v-highlight :html="false">{{ guidance }}</vue-markdown>
    </template>
  </PreviewPane>
</template>

<script setup>
  // import ProgressBar from '../components/UI/ProgressBar.vue';
  // import ColumnChecksCell from './ColumnChecksCell.vue';

  import IssueInstanceModal from '@/modals/IssueInstance/Create';
  import IssueInstanceViewModal from '@/modals/IssueInstance/View';
  import AddExistingCheckItemModal from '../modals/AddExistingCheckItem.vue';

  import SeverityLine from '@/components/UI/SeverityLine.vue';
  import SlidePanel from '@/components/UI/SlidePanel.vue';
  import PreviewPane from '@/components/UI/PreviewPane.vue';
  import Pill from '@/components/UI/Pill';

  import FilterBar from '@/components/UI/FilterBar.vue';
  import FilterOpt from '@/components/UI/FilterOpt.vue';
  import FilterSort from '@/components/UI/FilterSort.vue';

  import Tooltip from '@/components/UI/Tooltip.vue';
  import TooltipIcon from '@/components/Helpers/TooltipIcon.vue';
  
  import { toRefs, ref, computed, inject, onMounted, onUnmounted, watch } from 'vue';
  import { useQuery, useLazyQuery, useMutation, useSubscription } from "@vue/apollo-composable";
  import { useStore } from "vuex";
  import gql from "graphql-tag";
  import { formatDistance, isBefore, subYears } from "date-fns";

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );
  const user = computed( () => store.getters.user );

  const filterOpts = ref( {
    untested: 'Untested',
    pass: 'Pass',
    fail: 'Fail',
    advisory: 'Advisory',
    
  } );

  if( !store.getters.hasPermission( 'SimpleMode', 'NoNotApplicable' ) ) filterOpts.value['not-applicable'] = 'Not Applicable';

  const props = defineProps( {
    reportVersion: String,
    column: String,
    type: String,
    progress: Number,

    editable: {
      type: Boolean,
      default: () => true,
    },
  } );

  const { reportVersion, column, type, progress, editable } = toRefs( props );

  const alerts = inject( 'alerts' );
  const confirm = inject( 'confirm' );

  const tooltipEl = ref( null );

  const slidepanel = ref( null );
  const modal = ref( null );
  const issueviewmodal = ref( null );
  const addexistingmodal = ref( null );

  const filterBar = ref( null );
  const filters = ref( {
    status: [],
  } );
  const sort = ref( 'default' );

  const clearAllFilters = () => {
    filters.value = {
      status: [],
    };
  };

  // const tab = ref( 'empty' );
  const subscriptionVariables = ref( {
    spreadsheet: null,
  } );

  const { result: ssResult, onResult, loading, refetch, onError } = useQuery( gql`
    query SpreadsheetPage($reportVersion: ObjectID!, $page: Int!, $column: ObjectID) {
      page: SpreadsheetPage(reportVersion: $reportVersion, page: $page, column: $column) {
        _id
        number,
        title,
        userJourney {
          _id
          title
        }
        deleted,
        rows {
          newSection
          template {
            _id
            section
            ref
            header
            details
            criterion {
              _id
              description
            }
          }
          columns {
            id,
            header,
            type,
            page {
              _id
              host
              path
            }

            cell {
              _id
              row
              column
              type
              items {
                done,
                text,
                instance,
              }
              updated
              hasUnassoc #issue exists but not in cell
              hasItemUnassoc #item in cell issues doesn't have page assoc
              hasClosed
            }
          }
        }
      }
    }
  `,
  {
    reportVersion,
    page: 0,
    column,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  onError( error => alerts.coded( 'E095', 'F10001', '', error ) );

  const emit = defineEmits( [ 'changed', 'guidance' ] );

  const dataChanged = () => { emit( 'changed', true ) };

  const { onResult: onNewData } = useSubscription( gql`
    subscription SpreadsheetCellChanged($spreadsheet: String!) {
      cell: SpreadsheetCellChanged(spreadsheet: $spreadsheet) {
        _id,
        row,
        column,
        type,
        sheet
        items {
          done,
          text,
          instance,
        }
      }
    }
  `,
  subscriptionVariables );

  onNewData( ( { data } ) => {
    if( data.cell.column === column.value ) {
      for( let row = 0; row <= ( ssResult.value.page.rows.length - 1 ); row++ ) {
        if( ssResult.value.page.rows[row].template._id === data.cell.row && ssResult.value.page.rows[row].columns[0].id === data.cell.column ) {
          ssResult.value.page.rows[row].columns[0].cell.items = data.cell.items;
          dataChanged();
        }
      }
    }
  } );

  onResult( () => {
    if( !ssResult.value.page ) return;

    subscriptionVariables.value = {
      spreadsheet: ssResult.value.page._id,
    };
    ssResult.value.page.rows = ssResult.value.page.rows.map( r => {
      return { ...r, checked: false };
    } );
  } );

  const sortedRows = computed( () => {
    const { rows } = ssResult.value.page;
    switch( sort.value ) {
      case 'latest': {
        return rows.sort( ( a, b ) => new Date( b.columns[0].cell.updated ) - new Date( a.columns[0].cell.updated ) );
      }
      case 'oldest': {
        return rows.sort( ( a, b ) => new Date( a.columns[0].cell.updated ) - new Date( b.columns[0].cell.updated ) );
      }
      case 'default':
      default: return rows;
    }
  } );

  const sectionStatus = section => {
    const rows = ssResult.value.page.rows.filter( r => r.template.section == section ).map( r => r.checked );

    if( rows.indexOf( true ) >= 0 && rows.indexOf( false ) >= 0 ) return 'mixed';
    else if( rows.indexOf( true ) >= 0 ) return 'true';

    return 'false';
  };

  const sectionIcon = section => {
    const status = sectionStatus( section );
    if( status == 'true' ) return 'square-check';
    else if( status == 'mixed' ) return 'square-minus';

    return 'square';
  };

  const sectionToggle = section => {
    const status = sectionStatus( section );

    let toState = true;

    if( status == 'true' ) toState = false;

    ssResult.value.page.rows = ssResult.value.page.rows.map( r => {
      if( r.template.section == section ) r.checked = toState;

      return r;
    } );
  };

  const rowsSelected = computed( () => {
    const num = ssResult.value.page.rows.filter( r => r.checked ).length;
    
    if( num > 0 ) slidepanel.value.reset();

    return num;
  } );

  const deselectAll = () => { ssResult.value.page.rows = ssResult.value.page.rows.map( r => { return { ...r, checked: false } } ) };

  const getCellStatus = cell => {
    const statoos = [ 'fail', 'pass', 'advisory', 'na', 'not applicable' ];
    if( cell.items.length === 0 ) return 'empty';
    if( statoos.indexOf( cell.items[0].text.toLowerCase() ) >= 0 ) return cell.items[0].text;

    return 'untested';
  };

  const { mutate: updateSpreadsheetCell } = useMutation(
    gql`
    mutation updateSpreadsheetCell($id: ObjectID!, $items: [SpreadsheetCellItemInput], $origin: String) {
      updateSpreadsheetCell(id: $id, items: $items, origin: $origin) {
        _id,
        updated
      }
    }
  ` );

  const setStatusOn = ( cell, status, ml = false ) => {
    const myCellStatus = getCellStatus( cell );
    let myCellHasItems = false;
    if( myCellStatus == 'untested' && cell.items.length > 0 && ( cell.items[0].text != '' || cell.items[0].instance != '' ) ) myCellHasItems = true;
    if( myCellStatus != 'untested' && cell.items.length > 1 && ( cell.items[1].text != '' || cell.items[1].instance != '' ) ) myCellHasItems = true;

    if( myCellStatus == 'untested' && myCellHasItems ) {
      cell.items.unshift( {
        done: false,
        text: status,
        instance: ml ? 'ml' : '',
      } );
    } else if( cell.items.length ) {
      cell.items[0].text = status;
      cell.items[0].instance = ml ? 'ml' : '';
    } else {
      cell.items.push( {
        done: false,
        text: status,
        instance: ml ? 'ml' : '',
      } );
    }
    cell.saving = true;
    updateSpreadsheetCell( {
      id: cell._id,
      items: cell.items.map( item => {
        return {
          done: item.done,
          text: item.text,
          instance: item.instance,
        };
      } ),
      origin: 'Guided',
    } ).then( ( { data } ) => {
      cell.updated = data.updateSpreadsheetCell.updated;
      setTimeout( () => {
        cell.saving = false;
      }, 1000 );
    } ).catch( error => {
      alerts.generic( error ); //see notifications spreadsheet
    } );
  };

  const setSelectedStatus = status => {
    for( const index in ssResult.value.page.rows ) {
      if( !Object.prototype.hasOwnProperty.call( ssResult.value.page.rows, index ) ) continue;
      const row = ssResult.value.page.rows[ index ];
      if( !row.checked ) continue;

      const { cell } = row.columns[0];
      setStatusOn( cell, status );
    }

    deselectAll();
  };

  const openModal = ( row, func ) => {
    modal.value.show();
    modal.value.setRow( row );
    if( type.value == 'page' ) {
      modal.value.presetPage( column.value );
    } else if( type.value == 'component' ) {
      modal.value.presetComponent( column.value );
    }
    // modalCell.value = row;
    // newInstance.value = func;
  };

  const doTemplatesQuery = computed( () => !loading && !ssResult.value );

  const { result: templateResult, onResult: onTemplates, loading: templateLoading, refetch: refetchTemplates } = useQuery( gql`
    {
      templates: SpreadsheetTemplates {
        _id,
        name,
        description,
        published,
        promoted
      }
    }
  `,
  {
    fetchPolicy: 'no-cache',
    enabled: doTemplatesQuery,
  } );

  const templates = ref( {} );
  const newTemplate = ref( null );
  const templateDescs = ref( {} );

  onTemplates( ( { data } ) => {
    if( !data ) return;
    
    const tmpTemplates = {};
    let promoted;
    for( const template of data.templates ) {
        if( template.published ) {
          templateDescs.value[template._id] = template.description;
          if( template.promoted ) {
            promoted = template;
          } else {
            tmpTemplates[template._id] = template.name;
          }
        }
      }

      newTemplate.value = promoted._id;
      const p = {};
      // eslint-disable-next-line no-unused-expressions
      p[promoted._id] = `${promoted.name} (recommended)`;

      templates.value = {
        ...p,
        ...tmpTemplates,
      };
  } );

  const { mutate: addSpreadsheetPage } = useMutation(
    gql`
    mutation addSpreadsheetPage($page: SpreadsheetPageInput!) {
      addSpreadsheetPage(page: $page) {
        _id
      }
    }
  ` );

  const useSheet = sheet => {
    const page = {
      reportVersion: reportVersion.value,
      template: sheet,
      title: `Sheet 1`,
      number: 0,
    };

    addSpreadsheetPage( {
      page,
    } ).then( () => {
      refetch();
    } );
  };

  const currentRow = ref( null );
  const tmpCell = ref( null );
  const guidanceOpen = ref( false );
  const guidance = ref( null );
  const guidanceType = ref( null );

  const sidePanelTitle = computed( () => {
    if( currentRow.value && currentRow.value.template ) return `${currentRow.value.template.ref} ${currentRow.value.template.header}`;

    return 'Check';
  } );

  const currentCell = computed( () => currentRow.value.columns[0].cell );

  const { mutate: sendOpenGuidance } = useMutation(
      gql`
        mutation ($page: String, $extra: String ) {
          sendNavigation(page: $page, extra: $extra )
        }
      ` );
    
  const guidancePane = ref( null );

  const showGuidance = ( details, guidanceOpened ) => {
    if( guidanceOpen.value ) {
      guidanceOpen.value = false;

      return;
    }

    sendOpenGuidance( { page: 'Guidance', extra: guidanceOpened } );

    const regex = /\[.*\]\((.*)\)/;
    const urlCheck = regex.exec( details );
    if( urlCheck && ( urlCheck[1].indexOf( 'https://docs.hugr.app/' ) == 0 || urlCheck[1].indexOf( 'https://design.homeoffice.gov.uk/' ) == 0 ) ) {
      guidanceType.value = 'iframe';
      // eslint-disable-next-line prefer-destructuring
      guidance.value = `https://cors.diginclusion.com/${urlCheck[1]}`;
    } else {
      guidanceType.value = 'markdown';
      guidance.value = details;
    }
    guidanceOpen.value = true;

    setTimeout( () => {
      guidancePane.value.doFocus();
    }, 100 );
  };

  const resetCheck = () => {
    guidanceOpen.value = false;
    guidance.value = null;
    guidanceType.value = null;
  };

  const saveCell = () => {
    // currentRow.value.cell.keepInTodo = !close;
    currentRow.value.columns[0].cell.items = tmpCell.value.items;
    currentRow.value.columns[0].cell.saving = true;
    
    updateSpreadsheetCell( {
      id: tmpCell.value._id,
      items: tmpCell.value.items.map( item => {
        return {
          done: item.done,
          text: item.text,
          instance: item.instance,
        };
      } ),
      origin: 'Guided',
    } ).then( ( { data } ) => {
      tmpCell.value.updated = data.updateSpreadsheetCell.updated;
      currentRow.value.columns[0].cell.updated = data.updateSpreadsheetCell.updated;
      setTimeout( () => {
        currentRow.value.columns[0].cell.saving = false;
      }, 1000 );
    } ).catch( error => {
      alerts.generic( error ); //see notifications spreadsheet
    } );
  };

  const cellStatus = computed( () => {
    if( tmpCell.value ) return getCellStatus( tmpCell.value );

    return 'untested';
  } );

  const cellHasItems = computed( () => {
    if( cellStatus.value == 'untested' && tmpCell.value.items.length > 0 && ( tmpCell.value.items[0].text != '' || tmpCell.value.items[0].instance != '' ) ) return true;
    if( cellStatus.value != 'untested' && tmpCell.value.items.length > 1 && ( tmpCell.value.items[1].text != '' || tmpCell.value.items[1].instance != '' ) ) return true;

    return false;
   } );

  const setStatus = status => {
    if( cellStatus.value == 'untested' && cellHasItems.value ) {
      tmpCell.value.items.unshift( {
        done: false,
        text: status,
        instance: '',
      } );
    } else {
      tmpCell.value.items[0].text = status;
    }
    saveCell();
  };

  const issues = ref( {} );
  const identifiersToGet = ref( [] );

  const { load: loadIssue, refetch: refetchIssue, onResult: onIssue } = useLazyQuery( gql`
    query IssuesFromArray($identifiers: [String]!) {
      issues: IssuesFromArray(identifiers: $identifiers) {
        _id
        identifier
        reason
        priority
        template {
          _id
          identifier
          title
          severity
        }
        solutions {
          _id
          identifier
          title
        }
        page {
          _id
        }
        component {
          _id
        }
        others {
          _id
          page {
            _id
          }
        }
        status
      }
    }
  `,
  {
    identifiers: identifiersToGet,
  },
  {
    fetchPolicy: 'no-cache',
  } );

  onIssue( ( { data } ) => {
    issues.value = {};
    for( const issue of data.issues ) {
      issues.value[ issue.identifier ] = {
        ...issue,
      };
    }
  } );

  const getItemIssues = () => {
    identifiersToGet.value = tmpCell.value.items.map( i => i.instance ).filter( i => i != '' && i != null );
    // eslint-disable-next-line no-unused-expressions
    loadIssue() || refetchIssue();
  };

  const addItem = item => {
    tmpCell.value.items.push( {
      text: item,
      instance: item,
      done: true,
    } );
    saveCell();
    getItemIssues();
  };

  const potentialIssues = ref( [] );
  const potentialParams = ref( {} );
  const { load: loadRefIssues, refetch: updateRefIssues, onResult: onRefIssues } = useLazyQuery( gql`
    query IssuesFromPageOrComponentAndReference($reference: String!, $pageOrComponent: ObjectID!) {
      issues: IssuesFromPageOrComponentAndReference(reference: $reference, pageOrComponent: $pageOrComponent) {
        _id
        identifier
        reason
        priority
        template {
          _id
          identifier
          title
          severity
        }
        solutions {
          _id
          identifier
          title
        }
      }
    }
  `,
  potentialParams,
  {
    fetchPolicy: 'no-cache',
  } );

  onRefIssues( ( { data } ) => {
    if( !data ) return;

    const { issues } = data;

    const issuesOnCell = currentCell.value.items.filter( i => i.instance ).map( i => i.instance.trim() ).filter( i => i != '' );

    for( const issue of issues ) {
      if( issuesOnCell.indexOf( issue.identifier.trim() ) == -1 ) potentialIssues.value.push( issue );
    }
  } );

  const showCell = row => {
    currentRow.value = row;
    tmpCell.value = JSON.parse( JSON.stringify( row.columns[0].cell ) );
    potentialParams.value = {
      reference: currentRow.value.template.ref,
      pageOrComponent: currentCell.value.column,
    };
    loadRefIssues();
    getItemIssues();
    slidepanel.value.show();
    deselectAll();
  };

  const clearPreselect = () => {
    for( const myrow of ssResult.value.page.rows ) {
      myrow.preselect = false;
    }
  };

  const selectCheck = ( e, row ) => {
    document.getSelection().removeAllRanges();
    if( e.ctrlKey || rowsSelected.value > 0 ) row.checked = !row.checked;
    else if( e.shiftKey ) {
      for( const i in ssResult.value.page.rows ) {
        if( ssResult.value.page.rows[ i ]
            && typeof ssResult.value.page.rows[ i ].preselect != 'undefined'
            && ssResult.value.page.rows[ i ].preselect ) ssResult.value.page.rows[ i ].checked = true;
      }
    } else showCell( row );

    clearPreselect();
  };

  const stagedRow = ref( null );
  const shadowFirstSelected = ref( 0 );

  const hoverRow = ( e, row ) => {
    clearPreselect();
    stagedRow.value = row;
    if( e.ctrlKey ) row.preselect = true;
    else if( e.shiftKey || e.key == 'Shift' ) {
      row.preselect = true;
      let firstSelected = ssResult.value.page.rows.map( r => r.checked ).indexOf( true );
      const thisOne = ssResult.value.page.rows.map( r => r.template._id ).indexOf( row.template._id );
      if( firstSelected == -1 ) firstSelected = shadowFirstSelected.value;

      if( thisOne > firstSelected ) {
        for( let i = firstSelected; i < thisOne; i++ ) {
          ssResult.value.page.rows[ i ].preselect = true;
          if( e.buttons == 1 ) ssResult.value.page.rows[ i ].checked = true;
        }
      } else {
        for( let i = firstSelected; i > thisOne; i-- ) {
          ssResult.value.page.rows[ i ].preselect = true;
          if( e.buttons == 1 ) ssResult.value.page.rows[ i ].checked = true;
        }
      }
    }
  };

  const unhoverRow = ( e, row ) => {
    row.preselect = false;
  };

  const shiftListener = e => {
    if( e.key == 'Shift' || e.key == 'Control' ) {
      shadowFirstSelected.value = ssResult.value.page.rows.map( r => r.template._id ).indexOf( stagedRow.value.template._id );
      hoverRow( e, stagedRow.value );
    }
  };

  const unshiftListener = e => {
    shadowFirstSelected.value = 0;
    if( e.key == 'Shift' || e.key == 'Control' ) clearPreselect();
  };

  let saveTimeout = false;
  const updateNote = ( item, text ) => {
    item.text = text;
    if( !saveTimeout ) {
      saveTimeout = setTimeout( () => {
        saveCell();
        saveTimeout = false;
      }, 2000 );
    }
  };

  const editNote = ( item, index ) => {
    if( item.text.toLowerCase() == item.instance.toLowerCase() ) item.text = '';
    item.forceEdit = true;

    setTimeout( () => document.getElementById( `itemnote-${index}` ).focus(), 100 );
  };

  const deleteNote = index => {
    tmpCell.value.items.splice( index, 1 );
    saveCell();
  };

  const addNote = () => {
    tmpCell.value.items.push( {
      text: '',
      instance: null,
      done: null,
    } );
    setTimeout( () => document.getElementById( `itemnote-${tmpCell.value.items.length - 1}` ).focus(), 100 );
  };

  const { mutate: addIssueInstancePageByIdentifier } = useMutation(
    gql`
    mutation addIssueInstancePageByIdentifier($identifier: String!, $page: ObjectID!) {
      instance: addIssueInstancePageByIdentifier(identifier: $identifier, page: $page) {
        _id
      }
    }
  ` );

  const addIssueToPage = identifier => {
    addIssueInstancePageByIdentifier( {
      identifier,
      page: currentCell.value.column,
    } ).then( () => {
      alerts.success( "Issue added to page" );
      setTimeout( () => {
        getItemIssues();
      }, 300 );
    } ).catch( error => {
      alerts.generic( error );
    } );
  };

  const { mutate: addComment } = useMutation(
    gql`
    mutation addComment($comment: CommentInput!) {
      comment: addComment(comment: $comment) {
        _id
      }
    }
  ` );

  const { mutate: setIssueInstanceStatus } = useMutation(
    gql`
    mutation setIssueInstanceStatus($id: ObjectID!, $status: String!) {
      instance: setIssueInstanceStatus(id: $id, status: $status) {
        oldStatus
        issue {
          status
        }
      }
    }
  ` );

  const doSetInstanceStatus = ( id, index ) => {
    setIssueInstanceStatus( {
      id,
      status: 'closed-fixed',
    } ).then( () => {
      alerts.success( 'Status changed', `Issue instance has been marked as resolved` );

      deleteNote( index );

      confirm.input( 'Add a comment about this change?', 'Fixed:' ).then( ( [ yesNo, commentToAdd ] ) => {
        if ( yesNo ) {
          addComment( {
            comment: {
              reference: id,
              from: user.value.id,
              contents: commentToAdd,
              edited: false,
            },
          } ).then( () => {
            alerts.success( 'Comment posted!', `Your comment has successfully been posted.` );
          } ).catch( () => {
            alerts.error( `The comment can't be added`, `It's not you it's us. We're still in Alpha so please bear with us and <a target="_blank" href="https://hugr.community/c/Post-any-questions-were-here-to-help-and-hopefully-the-answers-will-help-others-too/bugs/">report a bug</a>` );
          } );
        }
      } );
    } ).catch( () => {
      alerts.error( `Issue status not changed`, `There's a bug and you've found it` );
      //TODO this should change it back
    } );
  };

  const stagedIssueIdentifier = ref( false );
  const doStagedIssueQuery = ref( false );

  const { loading: stagedIssueLoading, onResult: stagedIssueResult, refetch: stagedIssueRefetch } = useQuery( gql`
    query IssueInstance($identifier: String!) {
      issue: IssueInstance(identifier: $identifier) {
        _id
        identifier
      }
    }
  `,
  {
    identifier: stagedIssueIdentifier,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doStagedIssueQuery,
  } );

  const markResolved = ( identifier, index ) => {
    stagedIssueIdentifier.value = identifier;
    doStagedIssueQuery.value = true;
    stagedIssueResult( ( { data } ) => {
      doSetInstanceStatus( data.issue._id, index );
      doStagedIssueQuery.value = false;
    } );
  };

  const mark = criteria => {
    const rows = ssResult.value.page.rows.filter( r => criteria.indexOf( r.template.ref ) >= 0 );

    for( const row of rows ) {
      const { cell } = row.columns[0];
      setStatusOn( cell, 'Not Applicable', true );
    }
  };

  const resolvedFromModal = i => {
    const index = tmpCell.value.items.map( i => i.instance ).indexOf( i );
    deleteNote( index );
  };

  const modalClosed = () => {
    getItemIssues();
  };

  const mutateIssue = ( oldI, newI ) => {
    const index = tmpCell.value.items.map( i => i.instance ).indexOf( oldI );
    tmpCell.value.items[ index ].instance = newI;
    if( tmpCell.value.items[ index ].text == oldI ) tmpCell.value.items[ index ].text = newI;
    saveCell();
    getItemIssues();
  };

  const ticker = ref( 0 );
  let tickerInterval;

  const quickCheckOpen = ( row, mouse ) => {
    if( mouse ) row.columns[0].cell.mouseIsOver = true;
    row.columns[0].cell.showOpts = true;
  };

  const quickCheckClose = ( row, mouse ) => {
    if( mouse ) row.columns[0].cell.mouseIsOver = false;
    const pill = document.getElementById( `pill-${row.template.ref}` );
    const dropdown = document.getElementById( `quickcheck-${row.template.ref}` );
    
    if ( !dropdown ) {
      row.columns[0].cell.showOpts = false;

      return;
    }
    
    setTimeout( () => {
      if( !pill.contains( document.activeElement )
        && !dropdown.contains( document.activeElement )
        && !row.columns[0].cell.mouseIsOver ) row.columns[0].cell.showOpts = false;
    }, 100 );
  };

  onMounted( () => {
    document.addEventListener( 'keydown', shiftListener );
    document.addEventListener( 'keyup', unshiftListener );
    tickerInterval = setInterval( () => { ticker.value++ }, 1000 );
  } );

  onUnmounted( () => {
    document.removeEventListener( 'keydown', shiftListener );
    document.removeEventListener( 'keyup', unshiftListener );
    clearInterval( tickerInterval );
  } );

  // const goToIssue = identifier => { emit( 'goToIssue', identifier ) };

  defineExpose( {
    mark,
  } );

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';
  
  .Checks {
    position: relative;
    border: 1px solid $hugr-colours-grey;
    // background: lighten( $hugr-colours-grey, 15% );
    padding: 16px;

    &_Heading {
      margin-top: 0;
    }

    &_Selected {
      position: fixed;
      width: 60%;
      bottom: 32px;
      left: 20%;
      z-index: 99999;

      display: flex;
      gap: 16px;
      align-items: center;
      background: lighten( $dig-blue, 60% );
      border: 1px solid $dig-blue;
      border-radius: 4px;
      color: $dig-blue;
      &_Count {
        background: $dig-blue;
        font-size: 2.2em;
        color: white;
        padding: 4px 12px;
        align-self: stretch;
      }
      &_Opts {
        width: 75%;
        span {
          margin-right: 8px;
          font-size: 0.8em;
          font-weight: bold;
        }
        &_Button {
          border: none;
          background: transparent;
          padding: 0;
          margin-right: 4px;
          cursor: pointer;
        }
      }
      &_Cancel {
        position: absolute;
        right: 16px;
      }
    }

    &_SectionHead {
      &_Title {
        display: inline-block;
        margin-left: 8px;
      }
    }

    &_Item {
      background: $hugr-colours-input-surface;
      border: 1px solid $hugr-colours-input-surface;
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      gap: 8px;

      &._checked {
        background: lighten( $dig-blue, 70% );
        border: 1px solid lighten( $dig-blue, 20% );
      }
      &._hover {
        border: 1px solid lighten( $dig-blue, 20% );
      }

      &_Title {
        display: flex;
        gap: 8px;
        &_Select {
          align-self: center;
        }
        &_Link {
          align-self: center;
          font-size: 0.9em;
          text-decoration: none;
          margin-left: 4px;
        }
      }

      &_Status {
        align-self: center;
        text-align: right;

        &_Top {
          position: relative;
          &_Dropdown {
            position: absolute;
            background: black;
            width: 250px;
            // height: 250px;
            z-index: 9999;
            color: white;
            margin-top: 12px;
            border-radius: 12px;

            right: -130px;
            text-align: left;

            &:before {
              content: "";
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-bottom: 12px solid black;
              position: absolute;
              top: -12px;
              left: 94px;
            }

            &_Inner {
              width: 90%;
              padding: 12px 6px;
              h4 {
                font-weight: bold;
                font-size: 0.9em;
                margin: 2px 6px 8px;
              }
              p {
                font-size: 0.8em;
                margin: 0px 6px;
              }
              &_Button {
                background: transparent;
                width: 100%;
                border: none;
                margin-bottom: 4px;

                .Pill {
                  width: 100%;
                }
              }
            }
          }
        }

        &_LastChecked {
          white-space: nowrap;
          font-size: 0.7em;
          color: lighten( $hugr-colours-primary, 10% );
          margin-left: 4px;
          margin-top: 4px;
          span {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .CheckCell {
    &_Description {
      display: flex;
      max-height: 58px;
      overflow: hidden;
      gap: 8px;
      color: $hugr-colours-primary;
      margin: 4px 0;
      flex-shrink: 0;
      svg {
        top: 4px;
      }
      span {
        font-size: 0.8em;
      }
    }

    &_StillCorrect {
      position: relative;
      background: lighten( $hugr-colours-grey, 15% );
      padding: 8px;
      margin-top: 8px;

      &_Top {
        display: flex;
        justify-content: space-between;
        
        &_Heading {
          font-size: 0.9em;
          font-weight: 600;
          margin: 0px 0 8px;
        }
        &_LastChecked {
          white-space: nowrap;
          font-size: 0.7em;
          color: lighten( $hugr-colours-primary, 10% );
          margin-left: 4px;
          margin-top: 4px;
          span {
            margin-left: 4px;
          }
        }
      }
      button {
        background: transparent;
        padding: 0;
        border: none;
        cursor: pointer;
        margin-left: 4px;
      }
    }

    &_Outcome {
      flex-shrink: 0;
      h3 {
        font-size: 0.9em;
        font-weight: 600;
        margin: 8px 0;
      }
      h4 {
        font-size: 0.8em;
        font-weight: 600;
        margin: -8px 0 8px;
        color: lighten( $hugr-colours-primary, 10% );
      }
      &_Opts {
        &_Button {
          background: transparent;
          padding: 0;
          border: none;
          cursor: pointer;
          margin-right: 4px;
        }
      }
    }
    &_GuidanceBtn {
      text-decoration: none;
      font-size: 0.8em;
      font-weight: 600;
      margin-top: 16px;
      display: block;
    }

    &_Issues {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $hugr-colours-grey;
      flex-grow: 1;

      @include vertical-scroll;

      h3 {
        font-size: 0.9em;
        font-weight: 600;
        margin: 8px 0;
      }
      p {
        color: $hugr-colours-primary;
        font-size: 0.8em;
        margin: 4px 0;
      }
      &_Btns {
        display: block;
        margin: 16px 0;
        span {
          margin: 0 8px;
          color: $hugr-colours-primary;
        }
      }

      &_List {
        &_PotentialTitle {
          h5{
            margin-bottom: 0;
            font-weight: 500;
          }
          p{
            margin-top: 0;
          }
        }
        
        &_Item {
          background: lighten( $hugr-colours-grey, 15% );
          padding: 4px;
          margin-bottom: 16px;

          &._potential {
            background: lighten( $hugr-colours-yellow, 45% );
          }

          &_Head {
            border-bottom: 1px solid $hugr-colours-grey;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &_Left{
              svg {
                background: $hugr-colours-grey;
                margin: 0 8px;
                border-radius: 16px;
                padding: 4px 5px 6px 5px;
                font-size: 0.8em;
                &._closed {
                  background: $hugr-colours-pills-high;
                  color: $hugr-colours-pills-high-text;
                }
                &._notonpage, &._shouldbeonpage {
                  background: $hugr-colours-pills-warning;
                  color: $hugr-colours-pills-warning-text;
                }
              }
              &_Additional {
                font-size: 0.8em;
                color: $hugr-colours-primary;
                vertical-align: middle;
                top: -6px;
                position: relative;
              }
              button {
                vertical-align: middle;
                top: -4px;
              }
            }
            &_Right {
              font-size: 0.9em;
              color: $hugr-colours-primary;
              &_Menu {
                display: inline-block;
                width: 20px;
              }
            }
          }

          &_Body {
            dl {
              div {
                display: flex;
                justify-content: flex-start;
                gap: 32px;
                font-size: 0.8em;
                color: $hugr-colours-primary;
                margin: 8px 0;
                dt {
                  white-space: nowrap;
                  font-weight: bold;
                  width: 100px;
                  flex-shrink: 0;
                }
                dd {
                  text-align: left;
                  margin-left: 0;
                  flex-grow: 1;
                }
              }
            }

            &_Note {
              &:focus-within {
                outline: 1px solid;
              }
              dd:focus {
                outline: 0;
              }
            }
          }
        }

        &_Note {
          display: flex;
          gap: 16px;
          align-items: center;
          background: lighten( $dig-blue, 60% );
          padding: 8px 12px;
          margin-bottom: 16px;
          font-size: 1em;
          &:focus-within {
            outline: 1px solid;
          }

          svg {
            background: lighten( $dig-blue, 40% );
            border-radius: 16px;
            padding: 5px;
            font-size: 0.8em;
          }

          p {
            flex-grow: 1;
            &:focus {
              outline: 0;
            }
          }

          &_Menu {
            font-size: 0.9em;
            display: inline-block;
            width: 20px;
            flex-grow: 0;
            right: -8px;
          }
        }
      }
    }

    &_GuidanceFrame {
      width: 100%;
      height: calc( 100vh - 200px );
      border: none;
    }
  }

  .AddSheet {
    padding: 32px;
    &_Button {
      float: right;
      margin-top: 20px;
    }

    &_Options {
      border: 1px solid $hugr-colours-grey;
      border-radius: 10px;
      &_Option {
        border-bottom: 1px solid $hugr-colours-grey;
        padding: 8px;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        h3 {
          margin: 0;
        }
        p {
          margin-bottom: 0;
        }
        button {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }
    }
  }

  ._darkMode .Checks {
    &_Item {
      background: darken( $hugr-colours-primary, 5% );
      border: 1px solid darken( $hugr-colours-primary, 10% );
      &_Status{
        &_LastChecked {
          color: darken( white, 25% );
        }
      }
    }
  }

  ._darkMode .CheckCell {
    &_Description {
      color: darken( white, 25% );
    }

    &_Issues {
      > p {
        color: darken( white, 25% );
      }
      &_Btns {
        > span {
          color: darken( white, 25% );
        }
      }

      &_List {
        &_Item {
          &._potential {
            background: darken( $hugr-colours-yellow, 10% );
          }
        }
        &_PotentialTitle {
          p {
            color: darken( white, 25% );
          }
        }
      }
    }
  }
</style>
