<template>
  <Modal
    ref="modal"
    :title="$gettext( 'Clone %{projectString}', { projectString: $hugrConfig.projectStringCap } )"

    :showActions="true"

    :loading="acting"
    loadingText="Cloning..."

    :submitText="$gettext( 'Clone %{projectString}', { projectString: $hugrConfig.projectString } )"

    @submit="submit"
    @close="reset()"
    :ready="cloneOf">
    <p>{{$gettext('Making a clone of')}} {{cloneOf.identifier}}</p>
    <FormInput ref="title" idRoot="report_" label="Title" v-model="title" :validation="['not-empty']" :hintText="$gettext( 'This will appear at the top of your %{projectString}, you can edit it from settings later', { projectString: $hugrConfig.projectString } )"/>

    <template v-if="title!=''">
      <Notice size="micro" v-if="!manualid">
        <div>#<span>{{gendentifier}}</span></div>
        <div>
          {{ $gettext( "This is your %{projectString} identifier, it'll be used to find and identify your %{projectString} and issues within it.", { projectString: $hugrConfig.projectString } ) }}
        </div>
        <Button size="micro" @click="manualIdentifier()">{{$gettext('I want to set this manually')}}</Button>
      </Notice>
      <FormInput v-if="manualid" ref="identifier" idRoot="report_" :label="$gettext('Identifier')" v-model="identifier" :hintText="$gettext('This must be unique and may only contain letters, numbers, underscore and hyphen characters')"
        :validation="[{
          regex: /^[a-zA-Z0-9\-\_]*$/,
          message: $gettext('May only contain letters, numbers, underscore and hyphen characters')
        },
        'not-empty']"/>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';

import FormInput from '@/components/Form/Input';

export default {
  name: 'CloneReportModal',
  data() {
    return {
      cloneOf: null,

      identifier: '',
      title: '',
      owner: null,

      teams: false,

      manualid: false,
      fullTeam: false,

      acting: false,
    };
  },
  // watch: {
  //   'team' () {
  //     if(parseInt(this.user.aclevel)>=2&&this.team!=0) {
  //       this.$apollo.query({
  //         query: gql`
  //           query Team($id: ObjectID!) {
  //             team: Team(id: $id) {
  //               _id,
  //               identifier,
  //               customer {
  //                 _id
  //               }
  //             }
  //           }
  //         `,
  //         variables: {
  //           id: this.team
  //         }
  //       }).then(res => {
  //         this.fullTeam = res.data.team
  //       })
  //     }
  //   }
  // },
  methods: {
    manualIdentifier() {
      this.identifier = this.generateIdentifier;
      this.manualid = true;
    },
    setCustomer( customer, display ) {
      this.$refs.customerselect.select( customer, display );
      this.customer = customer;
    },
    submit() {
      const toValidate = [ 'title' ];
      if( this.manualid ) {
        toValidate.push( 'identifier' );
      }
      if( parseInt( this.user.aclevel < 2 ) ) {
        toValidate.push( 'customerselect' );
      }
      this.$hugrvalidate( toValidate, this ).then( ( { success } ) => {
        if( success ) {
          this.doSubmit();
        }
      } );
    },

    doSubmit() {
      // if(parseInt(this.user.aclevel)>=2&&this.team!='0') {
      //   customer = this.fullTeam.customer._id;
      // }

      let identifier;
      if( this.manualid ) {
        identifier = this.idprefix + this.identifier;
      } else {
        identifier = this.gendentifier;
      }
      this.acting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($id: ObjectID!, $title: String!, $identifier: String!, $owner: ObjectID) {
            report: cloneReport(id: $id, title: $title, identifier: $identifier, owner: $owner) {
              _id
              identifier
            }
          }
        `,
        // Parameters
        variables: {
          id: this.cloneOf._id,
          title: this.title,
          identifier,
          owner: this.user.id,
        },
      } ).then( () => {
        this.acting = false;
        this.reset();
        this.$alerts.success( this.$gettext( '%{projectString} cloned', { projectString: this.$hugrConfig.projectStringCap } ), this.$gettext( 'The %{projectString} has been cloned successfully', { projectString: this.$hugrConfig.projectString } ) );
      } ).catch( err => {
        this.acting = false;
        if ( err.message.indexOf( 'existing-report' ) >= 0 ) {
          this.$alerts.error( 'Non-unique identifier', 'Use a unique identifier to clone.' );
        } else {
          this.$alerts.coded( 'E044', 'F2301' ); //see notifications spreadsheet
        }
      } );
    },

    show( id ) {
      this.$apollo.query( {
        query: gql`
          query ($id: ObjectID!) {
            report: Report(id: $id) {
              _id
              title
              identifier
            }
          }
        `,
        variables: {
          id,
        },
      } ).then( res => {
        this.cloneOf = res.data.report;
        this.title = `${this.cloneOf.title} (clone)`;
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E045', 'F2302' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.identifier = '';
      this.title = '';
      this.owner = null;

      this.cloneOf = null;

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    idprefix() {
      let identifier = '';
      if( parseInt( this.user.aclevel ) >= 2 ) {
        if( this.team != '0' ) {
          identifier = `${this.fullTeam.identifier}_${identifier}`; //this should be the identifier for the team
        } else {
          identifier = `${this.user.name.replace( / /g, '' )}_${identifier}`;
        }
      }

      return identifier;
    },
    generateIdentifier() {
      const titleParts = this.title.split( ' ' );
      let titleInitials;
      if( titleParts.length > 1 ) {
        titleInitials = titleParts.slice( 0, 3 ).map( p => {

          if( p.length <= 4 ) {
            return p.toUpperCase();
          }
            //strip vowels and adjacent double letters
            const smlr = Array.from( p ).filter( ( c, k ) => {
              return !/[aeiou]/i.test( c ) || k == 0 || k == p.length - 1;
            } ).join( '' ).replace( /[^\w\s]|(.)(?=\1)/gi, "" ).toUpperCase();
            if( smlr.length <= 4 ) {
              return smlr;
            }

              return p[0].toUpperCase();

        } ).join( '' );
      } else {
        titleInitials = titleParts[0].toUpperCase();
      }

      return titleInitials.replace( /&/g, 'AND' );
    },
    gendentifier() {
      return this.idprefix + this.generateIdentifier + ( Math.random() * ( 999 - 101 ) + 101 ).toString().substring( 0,3 );
    },
  },
  components: {
    FormInput,
    // AriaSearchSelect
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 form {
   button.button {
     width: 100%;
     margin-top: 20px;
   }
 }
</style>
