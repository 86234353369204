<template>
  <Modal
    ref="modal"
    :title="$gettext('Edit Page:') + page.name"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="submitPage"

    @close="reset()">
    <template v-if="simplified">
      <FormInput idRoot="page_" :label="$gettext('Page URL')" v-model="combineUrl" ref="pageurl" @blur="doSplitUrl"/>
    </template>
    <template v-else>
      <FormInput idRoot="page_" :label="$gettext('Host URL')" v-model="page.host" :hintText="$gettext(`example: https://diginclusion.com`)"/>
      <FormInput idRoot="page_" :label="$gettext('Path')" v-model="page.path" :hintText="$gettext(`example: /contact`)"/>
    </template>

    <FormInput ref="name" idRoot="page_" :label="$gettext('Unique name')" v-model="page.name" :validation="['not-empty', 'max-70-chars']" />
    <FormInput idRoot="page_" :label="$gettext('Title')" v-model="page.title" />
    <FormImage ref="ssImage" idRoot="page_" :label="$gettext('Screenshot')" v-model="page.screenshot"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import { mapState } from 'vuex';

import FormInput from '@/components/Form/Input';
import FormImage from '@/components/Form/Image';

import UIModal from '@/components/UI/Modal';

export default {
  name: 'EditPageModal',
  data() {
    return {
      combineUrl: '',
      page: false,
      simplified: true,
      submitting: false,
    };
  },
  methods: {
    doCombineUrl() {
      this.combineUrl = `${this.page.host}${this.page.path}`;
    },
    doSplitUrl() {
      if( this.combineUrl == '' ) {
        this.page.host = '';
        this.page.path = '';
        
        return;
      }
      const regex = /([A-Za-z]*:\/\/)?([A-Za-z0-9\-.]*\.[A-Za-z0-9\-.]*)?([A-Za-z0-9%/#?=_\-&. ]*)/.exec( this.combineUrl );
      
      const protocol = regex[1] || 'https://';
      const domain = regex[2];
      const path = regex[3][0] === '/' ? regex[3] : `/${regex[3]}`;

      this.page.host = typeof domain === 'undefined' || domain === '' ? '' : `${protocol}${domain}`;
      this.page.path = path;

      this.doCombineUrl();
    },
    submitPage() {
      this.$hugrvalidate( [ 'name' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.doSplitUrl();
          this.$apollo.mutate( {
            mutation: gql`mutation updatePage($id: ObjectID!, $page: PageInput!) {
              page: updatePage(id: $id, page: $page) {
                _id,
                host,
                path,
                name,
                title
              }
            }`,
            variables: {
              id: this.page._id,
              page: {
                host: this.page.host,
                path: this.page.path,
                name: this.page.name,
                title: this.page.title,
                screenshot: this.page.screenshot,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.$emit( 'edited', res.data.page );
            this.reset();
          } ).catch( err => {
            this.submitting = false;
            if( err.message == 'page name is not unique' ) {
              this.$refs.name.addError( 'Page name must be unique' );
            } else {
              this.$alerts.coded( 'E060', 'F3301' ); //see notifications spreadsheet
            }
          } );
        }
      } );
    },
    show( pageid ) {
      this.$apollo.query( {
        query: gql`
          query Page($id: ObjectID!) {
            page: Page(id: $id) {
              _id,
              host,
              path,
              name,
              title
            }
          }
        `,
        variables: {
          id: pageid,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.page = { ...res.data.page };
        this.doCombineUrl();
        setTimeout( () => {
          this.$refs.modal.show();
        }, 100 );
      } ).catch( () => {
        this.$alerts.coded( 'E046', 'F3302' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.page = false;
      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    FormInput,
    FormImage,

    Modal: UIModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
