import { createRouter, createWebHistory, RouteConfig } from 'vue-router';

import Test from './views/Tests/Index.vue';

import Dashboard from './views/Dashboard.vue';
import DashboardNew from './views/DashboardNew.vue';

import Compliance from './views/Compliance.vue';

import Account from './views/Account/Index.vue';
import Rules from './views/Rules.vue';
import Guidelines from './views/Guidelines.vue';
import Issues from './views/Templates/Issues.vue';
import Solutions from './views/Templates/Solutions.vue';
import Customers from './views/Customers.vue';
import Teams from './views/Teams.vue';
import Portfolios from './views/Portfolios.vue';
import Counter from './views/Counter.vue';
import JiraCallback from './views/JiraCallback.vue';

import Notifications from './views/Notifications.vue';

import Reports from './views/Reports/Index.vue';
import ReportsView from './views/Reports/ViewLegacy.vue';
import ReportsHome from './views/Reports/Home.vue';
// import ReportsTestPlan from './views/Reports/TestPlan.vue';
import ReportsRender from './views/Reports/Render.vue';
import ReportsStatement from './views/Reports/Statement.vue';
import ReportsSettings from './views/Reports/Settings.vue';
import ReportPublish from './views/Reports/Publish.vue';
import ReportVersions from './views/Reports/Versions.vue';
import ReportsComponentView from './views/Reports/ComponentView.vue';
import ReportsPageView from './views/Reports/PageView.vue';
import ReportsSpreadsheet from './views/Reports/Spreadsheet.vue';
import ReportsPages from './views/Reports/Pages.vue';
import ReportsComponents from './views/Reports/Components.vue';
import ReportsUserJourneys from './views/Reports/UserJourneys.vue';
import ReportsIssues from './views/Reports/Issues.vue';

import IssueInstance from './views/Issue/Instance.vue';
import IssueView from './views/Issue/View.vue';

import TicketInstance from './views/Ticket.vue';
import UserProfile from './views/UserProfile.vue';

import TemplatesSpreadsheets from './views/Templates/Spreadsheets.vue';
import TemplatesSpreadsheet from './views/Templates/Spreadsheet.vue';

import ReportTypes from './views/Templates/ReportTypes.vue';
import ReportType from './views/Templates/ReportType.vue';
import ReportSections from './views/Templates/ReportSections.vue';
import EditReportSection from './views/Templates/EditReportSection.vue';
import Settings from './views/Settings.vue';

import AccessibilityStatementSections from './views/StatementSections/StatementSections.vue';
import EditAccessibilityStatementSection from './views/StatementSections/EditStatementSection.vue';

import PermissionGroups from './views/PermissionGroups.vue';

import MyTeam from './views/MyTeam.vue';
import MyPortfolio from './views/MyPortfolio.vue';

import MyPortfoliosAll from './views/MyPortfoliosAll.vue';

import Debug from './views/dev/Debug.vue';
import Components from './views/dev/Components.vue';
import System from './views/dev/System.vue';

import Metrics from './views/Metrics.vue';

import ImportTeams from './views/Import/Teams.vue';
import ImportUsers from './views/Import/Users.vue';
import ImportPortfolios from './views/Import/Portfolios.vue';
import ImportReports from './views/Import/Reports.vue';

import A11yStatement from './views/A11yStatement.vue';

import FourOhFour from './views/FourOhFour.vue';

import Config from '../config';

//ACL
// 0: Admin
// 1: Tester
// 2: Customer

const routes = [
  {
    path: '/feedback',
    beforeEnter() { location.href = 'https://forms.gle/MAAfDM6SmmgogrpA8' },
    component: { template: `<div>Redirecting to Feedback, if that doesn't work click the following link <a href="https://forms.gle/MAAfDM6SmmgogrpA8">https://forms.gle/MAAfDM6SmmgogrpA8</a></div>` },
  },
  {
    path: '/termsandconditions',
    beforeEnter() { location.href = `${Config.termsAndConditionsLink}` },
    component: { template: `<div>Redirecting to Terms and Conditions, if that doesn't work click the following link <a href="${Config.termsAndConditionsLink}">Terms and Conditions</a></div>` },
  },
  {
    path: '/softwarelicense',
    beforeEnter() { location.href = `${Config.softwareLicenseAgreementLink}` },
    component: { template: `<div>Redirecting to the Software License Agreement, if that doesn't work click the following link <a href="${Config.softwareLicenseAgreementLink}">Software License Agreement</a></div>` },
  },
  {
    path: '/privacypolicy',
    beforeEnter() { location.href = `${Config.privacyPolicyLink}` },
    component: { template: `<div>Redirecting to the Privacy Policy, if that doesn't work click the following link <a href="${Config.privacyPolicyLink}">Privacy Policy</a></div>` },
  },
  {
    path: '/help-centre',
    beforeEnter() { location.href = `${Config.helpCentreLink}` },
    component: { template: `<div>Redirecting to the the help centre, if that doesn't work click the following link <a href="${Config.helpCentreLink}">help centre</a></div>` },
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardNew,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Dashboard',
    },
  },
  {
    path: '/compliance',
    name: 'compliance',
    component: Compliance,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Compliance',
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'My Account',
    },
  },
  {
    path: '/account/:fragment',
    name: 'AccountFragment',
    component: Account,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'My Account',
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Notifications',
    },
  },
  {
    path: '/callback',
    name: 'JiraCallback',
    component: JiraCallback,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Jira',
    },
  },
  {
    path: '/dev/debug',
    name: 'Debug',
    component: Debug,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Debug',
    },
  },
  {
    path: '/dev/components',
    name: 'Components',
    component: Components,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Components',
    },
  },
  {
    path: '/dev/system',
    name: 'System',
    component: System,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'System',
    },
  },
  {
    path: '/metrics',
    name: 'Metrics',
    component: Metrics,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Metrics',
    },
  },

  /***********************************************************************************************
   *                                             REPORT
   ***********************************************************************************************/
  {
    path: '/reports/:identifier',
    alias: [
      '/reports/view/:identifier',

      '/assessments/:identifier',
      '/assessments/view/:identifier',

      '/products/:identifier',
      '/products/view/:identifier',

      '/projects/:identifier',
      '/projects/view/:identifier',
    ],
    name: 'ReportsView',
    component: ReportsHome,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report',
    },
  },
  {
    path: '/reports/:identifier/:version',
    alias: [
      '/reports/view/:identifier/:version',

      '/assessments/:identifier/:version',
      '/assessments/view/:identifier/:version',

      '/products/:identifier/:version',
      '/products/view/:identifier/:version',

      '/projects/:identifier/:version',
      '/projects/view/:identifier/:version',
    ],
    name: 'ReportsViewVersion',
    component: ReportsHome,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report',
    },
  },
  {
    path: '/reports/:identifier/legacy',
    alias: [
      '/reports/view/:identifier/legacy',

      '/assessments/:identifier/legacy',
      '/assessments/view/:identifier/legacy',

      '/products/:identifier/legacy',
      '/products/view/:identifier/legacy',

      '/projects/:identifier/legacy',
      '/projects/view/:identifier/legacy',
    ],
    name: 'ReportsViewLegacy',
    component: ReportsView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report',
    },
  },
  {
    path: '/reports/:identifier/:version/legacy',
    alias: [
      '/reports/view/:identifier/:version/legacy',

      '/assessments/:identifier/:version/legacy',
      '/assessments/view/:identifier/:version/legacy',

      '/products/:identifier/:version/legacy',
      '/products/view/:identifier/:version/legacy',

      '/projects/:identifier/:version/legacy',
      '/projects/view/:identifier/:version/legacy',
    ],
    name: 'ReportsViewVersionLegacy',
    component: ReportsView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report',
    },
  },

  {
    path: '/reports/:identifier/render',
    alias: [
      '/reports/render/:identifier',

      '/assessments/:identifier/render',
      '/assessments/render/:identifier',
      
      '/products/:identifier/render',
      '/products/render/:identifier',

      '/projects/:identifier/render',
      '/projects/render/:identifier',
    ],
    name: 'ReportsRender',
    component: ReportsRender,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report',
    },
  },
  {
    path: '/reports/:identifier/:version/render',
    alias: [
      '/reports/render/:identifier/:version',

      '/assessments/:identifier/:version/render',
      '/assessments/render/:identifier/:version',

      '/products/:identifier/:version/render',
      '/products/render/:identifier/:version',

      '/projects/:identifier/:version/render',
      '/projects/render/:identifier/:version',
    ],
    name: 'ReportsRenderVersion',
    component: ReportsRender,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Preview Report',
    },
  },
  {
    path: '/reports/:identifier/statement',
    alias: [
      '/reports/statement/:identifier',

      '/assessments/:identifier/statement',
      '/assessments/statement/:identifier',

      '/products/:identifier/statement',
      '/products/statement/:identifier',

      '/projects/:identifier/statement',
      '/projects/statement/:identifier',
    ],
    name: 'ReportsStatement',
    component: ReportsStatement,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Statement',
    },
  },
  {
    path: '/reports/:identifier/:version/statement',
    alias: [
      '/reports/statement/:identifier/:version',

      '/assessments/:identifier/:version/statement',
      '/assessments/statement/:identifier/:version',

      '/products/:identifier/:version/statement',
      '/products/statement/:identifier/:version',

      '/projects/:identifier/:version/statement',
      '/projects/statement/:identifier/:version',
    ],
    name: 'ReportsStatementVersion',
    component: ReportsStatement,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Statement',
    },
  },
  {
    path: '/view/statement/:shareId',
    name: 'ReportsStatementShare',
    component: ReportsStatement,
    meta: {
      acl: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], //no acl
      title: 'Report Statement Share',
    },
  },
  {
    path: '/view/:shareId',
    name: 'ReportsRender',
    component: ReportsRender,
    meta: {
      acl: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], //no acl
      title: 'Report Share',
    },
  },

  {
    path: '/view/:shareId/undecorated',
    name: 'ReportsRenderUndecorated',
    component: ReportsRender,
    meta: {
      acl: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], //no acl
      title: 'Report Share',
    },
  },
  {
    path: '/reports/:identifier/:version/publish',
    alias: [
      '/products/:identifier/:version/publish',
      '/assessments/:identifier/:version/publish',
      '/projects/:identifier/:version/publish',
    ],
    name: 'ReportPublish',
    component: ReportPublish,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Publish Report',
    },
  },
  {
    path: '/reports/:identifier/:version/versions',
    alias: [
      '/products/:identifier/:version/versions',
      '/assessments/:identifier/:version/versions',
      '/projects/:identifier/:version/versions',
    ],
    name: 'ReportVersions',
    component: ReportVersions,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Versions',
    },
  },
  {
    path: '/reports/:identifier/versions',
    alias: [
      '/products/:identifier/versions',
      '/assessments/:identifier/versions',
      '/projects/:identifier/versions',
    ],
    name: 'ReportVersions',
    component: ReportVersions,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Versions',
    },
  },

  {
    path: '/reports/:identifier/settings',
    alias: [
      '/reports/settings/:identifier',

      '/assessments/:identifier/settings',
      '/assessments/settings/:identifier',

      '/products/:identifier/settings',
      '/products/settings/:identifier',

      '/projects/:identifier/settings',
      '/projects/settings/:identifier',
    ],
    name: 'ReportsSettings',
    component: ReportsSettings,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Settings',
    },
  },
  {
    path: '/reports/:report/issue/:template', //is this still used?
    alias: [
      '/products/:report/issue/:template',
      '/assessments/:report/issue/:template',
      '/projects/:report/issue/:template',
    ],
    name: 'IssueView',
    component: IssueView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Issue',
    },
  },
  {
    path: '/reports/:report/:version/issue/:template',
    alias: [
      '/products/:report/:version/issue/:template',
      '/assessments/:report/:version/issue/:template',
      '/projects/:report/:version/issue/:template',
    ],
    name: 'IssueViewVersion',
    component: IssueView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Issue',
    },
  },
  {
    path: '/reports/:identifier/component/:component',
    name: 'ReportsIdentifierComponentView',
    component: ReportsComponentView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Component',
    },
  },
  {
    path: '/reports/component/:component',
    alias: [
      '/reports/component/:component',

      '/products/:identifier/component/:component',
      '/products/component/:component',

      '/assessments/:identifier/component/:component',
      '/assessments/component/:component',

      '/projects/:identifier/component/:component',
      '/projects/component/:component',
    ],
    name: 'ReportsComponentView',
    component: ReportsComponentView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Component',
    },
  },
  {
    path: '/reports/:identifier/page/:page',
    name: 'ReportsIdentifierPageView',
    component: ReportsPageView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Page',
    },
  },
  {
    path: '/reports/page/:page',
    alias: [
      '/reports/page/:page',

      '/products/:identifier/page/:page',
      '/products/page/:page',

      '/assessments/:identifier/page/:page',
      '/assessments/page/:page',

      '/projects/:identifier/page/:page',
      '/projects/page/:page',
    ],
    name: 'ReportsPageView',
    component: ReportsPageView,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Page',
    },
  },
  {
    path: '/reports/:identifier/spreadsheet',
    alias: [
      '/reports/spreadsheet/:identifier',

      '/assessments/:identifier/spreadsheet',
      '/assessments/spreadsheet/:identifier',

      '/products/:identifier/spreadsheet',
      '/products/spreadsheet/:identifier',

      '/projects/:identifier/spreadsheet',
      '/projects/spreadsheet/:identifier',
    ],
    name: 'ReportsSpreadsheet',
    component: ReportsSpreadsheet,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Spreadsheet',
    },
  },
  {
    path: '/reports/:identifier/:version/spreadsheet',
    alias: [
      '/reports/spreadsheet/:identifier/:version',

      '/assessments/:identifier/:version/spreadsheet',
      '/assessments/spreadsheet/:identifier/:version',

      '/products/:identifier/:version/spreadsheet',
      '/products/spreadsheet/:identifier/:version',

      '/projects/:identifier/:version/spreadsheet',
      '/projects/spreadsheet/:identifier/:version',
    ],
    name: 'ReportsSpreadsheetVersion',
    component: ReportsSpreadsheet,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Spreadsheet',
    },
  },
  /*********************************************************************
   *                      PAGES
   *********************************************************************/
  {
    path: '/reports/:identifier/pages',
    alias: [
      '/reports/:identifier/pages/:page',

      '/assessments/:identifier/pages',
      '/assessments/:identifier/pages/:page',

      '/products/:identifier/pages',
      '/products/:identifier/pages/:page',

      '/projects/:identifier/pages',
      '/projects/:identifier/pages/:page',
    ],
    name: 'ReportsPages',
    component: ReportsPages,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Pages',
    },
  },
  {
    path: '/reports/:identifier/pages/:page',
    alias: [ '/assessments/:identifier/pages/:page', '/products/:identifier/pages/:page' ],
    name: 'ReportsPagesPage',
    component: ReportsPages,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Pages',
    },
  },
  {
    path: '/reports/:identifier/:version/pages',
    alias: [
      '/reports/:identifier/:version/pages/:page',

      '/assessments/:identifier/:version/pages',
      '/assessments/:identifier/:version/pages/:page',

      '/products/:identifier/:version/pages',
      '/products/:identifier/:version/pages/:page',

      '/projects/:identifier/:version/pages',
      '/projects/:identifier/:version/pages/:page',
    ],
    name: 'ReportsPagesVersion',
    component: ReportsPages,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Pages',
    },
  },
  {
    path: '/reports/:identifier/:version/pages/:page',
    alias: [ '/assessments/:identifier/:version/pages/:page', '/products/:identifier/:version/pages/:page' ],
    name: 'ReportsPagesVersionPage',
    component: ReportsPages,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Pages',
    },
  },

  /*********************************************************************
   *                      COMPONENTS
   *********************************************************************/
  {
    path: '/reports/:identifier/components',
    alias: [
      '/reports/:identifier/components/:component',

      '/assessments/:identifier/components',
      '/assessments/:identifier/components/:component',

      '/products/:identifier/components',
      '/products/:identifier/components/:component',

      '/projects/:identifier/components',
      '/projects/:identifier/components/:component',
    ],
    name: 'ReportsComponents',
    component: ReportsComponents,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Components',
    },
  },
  {
    path: '/reports/:identifier/components/:component',
    alias: [ '/assessments/:identifier/components/:component', '/products/:identifier/components/:component' ],
    name: 'ReportsComponentsComponent',
    component: ReportsComponents,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Components',
    },
  },
  {
    path: '/reports/:identifier/:version/components',
    alias: [
      '/reports/:identifier/:version/components/:component',

      '/assessments/:identifier/:version/components',
      '/assessments/:identifier/:version/components/:component',

      '/products/:identifier/:version/components',
      '/products/:identifier/:version/components/:component',

      '/projects/:identifier/:version/components',
      '/projects/:identifier/:version/components/:component',
    ],
    name: 'ReportsComponentsVersion',
    component: ReportsComponents,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Components',
    },
  },
  {
    path: '/reports/:identifier/:version/components/:component',
    alias: [ '/assessments/:identifier/:version/components/:component', '/products/:identifier/:version/components/:component' ],
    name: 'ReportsComponentsVersionComponent',
    component: ReportsComponents,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Components',
    },
  },

  /*********************************************************************
   *                      USER JOURNEYS
   *********************************************************************/
  {
    path: '/reports/:identifier/journeys',
    alias: [
      '/reports/:identifier/journeys/:journey',

      '/assessments/:identifier/journeys',
      '/assessments/:identifier/journeys/:journey',

      '/products/:identifier/journeys',
      '/products/:identifier/journeys/:journey',

      '/projects/:identifier/journeys',
      '/projects/:identifier/journeys/:journey',
    ],
    name: 'ReportsJourneys',
    component: ReportsUserJourneys,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Journeys',
    },
  },
  {
    path: '/reports/:identifier/journeys/:journey',
    alias: [ '/assessments/:identifier/journeys/:journey', '/products/:identifier/journeys/:journey' ],
    name: 'ReportsJourneysJourney',
    component: ReportsUserJourneys,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Journeys',
    },
  },
  {
    path: '/reports/:identifier/:version/journeys',
    alias: [
      '/reports/:identifier/:version/journeys/:journey',

      '/assessments/:identifier/:version/journeys',
      '/assessments/:identifier/:version/journeys/:journey',

      '/products/:identifier/:version/journeys',
      '/products/:identifier/:version/journeys/:journey',

      '/projects/:identifier/:version/journeys',
      '/projects/:identifier/:version/journeys/:journey',
    ],
    name: 'ReportsJourneysVersion',
    component: ReportsUserJourneys,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Journeys',
    },
  },
  {
    path: '/reports/:identifier/:version/journeys/:journey',
    alias: [ '/assessments/:identifier/:version/journeys/:journey', '/products/:identifier/:version/journeys/:journey' ],
    name: 'ReportsJourneysVersionJourney',
    component: ReportsUserJourneys,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Journeys',
    },
  },
  /*********************************************************************
   *                      ISSUES
   *********************************************************************/
  {
    path: '/reports/:identifier/issues',
    alias: [
      '/products/:identifier/issues',
      '/assessments/:identifier/issues',
      '/projects/:identifier/issues',
    ],
    name: 'ReportsIssues',
    component: ReportsIssues,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Issues',
    },
  },
  {
    path: '/reports/:identifier/:version/issues',
    alias: [
      '/products/:identifier/:version/issues',
      '/assessments/:identifier/:version/issues',
      '/projects/:identifier/:version/issues',
    ],
    name: 'ReportsIssuesVersion',
    component: ReportsIssues,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Issues',
    },
  },
  {
    path: '/reports/:identifier/:version/issues/:instance',
    alias: [
      '/products/:identifier/:version/issues/:instance',
      '/assessments/:identifier/:version/issues/:instance',
      '/projects/:identifier/:version/issues/:instance',
    ],
    name: 'ReportsIssuesVersionItem',
    component: ReportsIssues,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Issue Instance',
    },
  },

  /*********************************************************************
   *                      ALL
   *********************************************************************/

  {
    path: '/reports',
    alias: [
      '/reports/find',

      '/assessments',
      '/assessments/find',

      '/products',
      '/products/find',

      '/projects',
      '/projects/find',
    ],
    name: 'Reports',
    component: Reports,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'All Reports',
    },
  },

  {
    path: '/reports/find/:method',
    alias: [
      '/assessments/find/:method',
      '/products/find/:method',
      '/projects/find/:method',
    ],
    name: 'ReportsMethod',
    component: Reports,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'All Reports',
    },
  },

  {
    path: '/reports/find/:method/:order',
    alias: [
      '/assessments/find/:method/:order',
      '/products/find/:method/:order',
      '/projects/find/:method/:order',
    ],
    name: 'ReportsMethodOrder',
    component: Reports,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'All Reports',
    },
  },

  {
    path: '/reports/find/:method/:order/:filtertype/:filter',
    alias: [
      '/assessments/find/:method/:order/:filtertype/:filter',
      '/products/find/:method/:order/:filtertype/:filter',
      '/projects/find/:method/:order/:filtertype/:filter',
    ],
    name: 'ReportsMethodOrderFilter',
    component: Reports,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'All Reports',
    },
  },

  /***********************************************************************************************
   *                                        END REPORT
   ***********************************************************************************************/

  {
    path: '/rules',
    name: 'rules',
    component: Rules,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Rules',
    },
  },
  {
    path: '/guidelines',
    name: 'guidelines',
    component: Guidelines,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Guidelines',
    },
  },
  {
    path: '/templates/issues',
    name: 'issues',
    component: Issues,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Issue Templates',
    },
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: Solutions,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Solutions',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
    meta: {
      acl: [ 0 ],
      title: 'Customers',
    },
  },
  {
    path: '/teams',
    name: 'teams',
    component: Teams,
    meta: {
      acl: [ 0 ],
      requiredPermissions: [ [ 'Teams','Read' ] ],
      title: 'Teams',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Teams,
    meta: {
      acl: [ 0 ],
      requiredPermissions: [ [ 'Users','Read' ] ],
      title: 'Users',
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Teams,
    meta: {
      acl: [ 0 ],
      requiredPermissions: [ [ 'Users','Read' ] ],
      title: 'Contacts',
    },
  },
  {
    path: '/team-contact',
    name: 'team-contact',
    component: Teams,
    meta: {
      acl: [ 0 ],
      title: 'Team Contact',
    },
  },
  {
    path: '/portfolios',
    name: 'portfolios',
    component: Portfolios,
    meta: {
      acl: [ 0 ],
      requiredPermissions: [ [ 'Portfolios','Update' ],[ 'Portfolios','Create' ] ],
      title: 'Portfolios',
    },
  },
  {
    path: '/counter',
    name: 'counter',
    component: Counter,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Counter',
    },
  },
  {
    path: '/team/:identifier',
    name: 'myteam',
    component: MyTeam,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'My Team',
    },
  },
  {
    path: '/portfolio/:identifier',
    name: 'myportfolio',
    component: MyPortfolio,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'My Portfolio',
    },
  },
  {
    path: '/myportfolios',
    name: 'myportfoliosall',
    component: MyPortfoliosAll,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'My Portfolios',
    },
  },
  {
    path: '/issue/:identifier',
    alias: '/issue/instance/:identifier',
    name: 'IssueInstance',
    component: IssueInstance,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Issue Instance',
    },
  },
  {
    path: '/ticket/:identifier',
    alias: '/ticket/instance/:identifier',
    name: 'TicketInstance',
    component: TicketInstance,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Ticket',
    },
  },
  {
    path: '/userprofile/:identifier',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'User Profile',
    },
  },
  {
    path: '/templates/spreadsheet',
    name: 'TemplatesSpreadsheets',
    component: TemplatesSpreadsheets,
    meta: {
      acl: [ 0 ],
      requiredPermissions: [ [ 'SpreadsheetTemplates','Create' ],[ 'SpreadsheetTemplates','Update' ] ],
      title: 'Spreadsheet Templates',
    },
  },
  {
    path: '/templates/spreadsheet/:id',
    name: 'TemplatesSpreadsheet',
    component: TemplatesSpreadsheet,
    meta: {
      acl: [ 0 ],
      requiredPermissions: [ [ 'SpreadsheetTemplates','Create' ],[ 'SpreadsheetTemplates','Update' ] ],
      title: 'Spreadsheet Template',
    },
  },
  {
    path: '/templates/report-types',
    name: 'ReportTypes',
    component: ReportTypes,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Types',
    },
  },
  {
    path: '/templates/report-sections',
    name: 'ReportSections',
    component: ReportSections,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'ReportSections', 'Create' ], [ 'ReportSections', 'Update' ] ],
      title: 'Report Sections',
    },
  },
  {
    path: '/templates/report-sections/:id',
    name: 'EditReportSection',
    component: EditReportSection,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'ReportSections', 'Create' ], [ 'ReportSections', 'Update' ] ],
      title: 'Report Section',
    },
  },
  {
    path: '/accessibility-statement/statement-sections',
    name: 'AccessibilityStatementSections',
    component: AccessibilityStatementSections,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Accessibility Statement Sections',
    },
  },
  {
    path: '/accessibility-statement/statement-sections/:id',
    name: 'EditAccessibilityStatementSection',
    component: EditAccessibilityStatementSection,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'AccessibilityStatementSections', 'Update' ] ],
      title: 'Edit Accessibility Statement Section',
    },
  },
  {
    path: '/permission-groups',
    name: 'PermissionGroups',
    component: PermissionGroups,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Permission Groups',
    },
  },
  {
    path: '/templates/report-type/:identifier',
    name: 'ReportType',
    component: ReportType,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Report Type',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'Settings', 'Update' ] ],
      title: 'Settings',
    },
  },
  {
    path: '/import/users',
    name: 'ImportUsers',
    component: ImportUsers,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'Import', 'Users' ] ],
      title: 'Import users',
    },
  },
  {
    path: '/import/teams',
    name: 'ImportTeams',
    component: ImportTeams,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'Import', 'Teams' ] ],
      title: 'Import teams',
    },
  },
  {
    path: '/import/portfolios',
    name: 'ImportPortfolios',
    component: ImportPortfolios,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'Import', 'Portfolios' ] ],
      title: 'Import portfolios',
    },
  },
  {
    path: '/import/reports',
    name: 'ImportReports',
    component: ImportReports,
    meta: {
      acl: [ 0, 1, 2 ],
      requiredPermissions: [ [ 'Import', 'Reports' ] ],
      title: 'Import Reports',
    },
  },

  {
    path: '/signup/:magicCode',
    name: 'Sign Up',
    component: Dashboard,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Dashboard',
    },
  },
  {
    path: '/signup/:magicCode/:email',
    name: 'Sign Up',
    component: Dashboard,
    meta: {
      acl: [ 0, 1, 2 ],
      title: 'Dashboard',
    },
  },

  {
    path: '/accessibility-statement',
    component: A11yStatement,
    meta: {
      acl: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], //no acl
      title: 'Accessibility Statement',
    },
  },

  {
    path: '/:pathMatch(.*)*',
    component: FourOhFour,
    meta: {
      acl: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], //no acl
      title: 'Page not found',
    },
  },
  {
    path: '/:pathMatch(.*)',
    component: FourOhFour,
    meta: {
      acl: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ], //no acl
      title: 'Page not found',
    },
  },
];

export default new createRouter( {
  routes,
  history: createWebHistory(),
} );
