<template>
  <Body :title="$gettext('Notifications')" :fill="true">
      <Search class="Search" :label="$gettext('Search for specific notifications')" v-model="search" :inUse="search!=''" :results="searchNotifications.length"/>
      <Loader v-if="loading" />
      <div v-else class="Notifications_Container">
        <div class="Notifications_Container_List">
          <div class="Notifications_Container_List_Navigation">
            <Button size="small" type="primary" @click="() => { page = 1 }">{{$gettext('First')}}</Button>
            <Button size="small" type="secondary" @click="decrease();">{{$gettext('Back')}}</Button>
            <div class="Notifications_Container_List_Navigation_Page">
              <input class="Notifications_Container_List_Navigation_Page_Input" ref="page" v-model="page" aria-label="Page" aria-describedby="maxPage"/> <span id="maxPage" :aria-label="`of ${maxPage}`">/ {{maxPage}}</span>
            </div>
            <Button size="small" type="secondary" @click="increment();">{{$gettext('Next')}}</Button>
            <Button size="small" type="primary" @click="() => { page = maxPage }">{{$gettext('Last')}}</Button>
          </div>
          <ul>
            <template v-if="!searchNotifications">
              <li v-for="notification in notificationsPage" :class="['Notifications_Container_List_Unit', notification.read?'_read':'_unread']" v-bind:key="'cus-' + notification._id">
                <div role="button" tabindex="0" @click="choose(notification)" @keyup.enter="chosen = notification" aria-controls="NotificationContainer">
                  <div class="Notifications_Container_List_Unit_Head">
                    {{ notification.title.split("/namesplit/")[0]  }}
                  </div>
                  <div class="Notifications_Container_List_Unit_Body">
                    <p v-html="notification.body"></p>
                  </div>
                </div>
              </li>
            </template>
            <template v-else>
              <li v-for="notification in searchNotificationsSlice" :class="['Notifications_Container_List_Unit', notification.read?'_read':'_unread']" v-bind:key="'cus-' + notification._id">
                <div role="button" tabindex="0" @click="choose(notification)" @keyup.enter="chosen = notification" aria-controls="NotificationContainer">
                  <div class="Notifications_Container_List_Unit_Head">
                    {{ notification.title.split("/namesplit/")[0]  }}
                  </div>
                  <div class="Notifications_Container_List_Unit_Body">
                    <p v-html="notification.body"></p>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="Notifications_Container_Content" id="NotificationContainer">
          <div v-if="!chosen" class="Notifications_Container_Content_Body">
            <span class="Notifications_Container_Content_Body_None" v-translate>No notification currently selected</span>
          </div>
          <div v-else class="Notifications_Container_Content_Body">
            <h3>{{chosen.title}}</h3>

            <div class="Notifications_Container_Content_Body_Details">
              <span class="Avatar"> {{senderAcronym}}</span>
              <span class="Notifications_Container_Content_Body_Details_D">
                <div>{{chosen.senderUser.name}}</div>
                <span>{{moment(chosen.timestamp).fromNow()}}</span>
              </span>
            </div>

            <div class="Notifications_Container_Content_Body_Content">
              <div v-html="chosen.body"></div>
            </div>

            <Button size="small" type="secondary" @click="$router.push(chosen.link)">{{$gettext('Go to notification reference')}}</Button>
          </div>
        </div>
      </div>
    <!-- </div> -->
    <!-- <Loader v-else /> -->
    <EditCustomerModal ref="customermodal" @success="$apollo.queries.customers.refetch()" />
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import EditCustomerModal from '@/modals/Customer/Edit';
import moment from 'moment';

export default {
  name: 'NotificationsView',
  data() {
    return {
      notificationsPage: false,
      total: false,
      loading: false,
      typingTimeout: false,
      searchNotifications: false,
      search: '',
      page: 1,
      chosen: false,
      notificationsPerPage: 20,
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Notifications',
      },
    } );
  },
  watch: {
    page() {
      this.checkPageInput();
    },
    search() {
      this.loading = true;
      clearTimeout( this.typingTimeout );
      if( this.search == '' ) {
        this.loading = false;
        this.searchNotifications = false;
      } else {
        this.typingTimeout = setTimeout( () => {
        this.$apollo.query( {
          query: gql`
            query NotificationsSearch($term: String!){
              notifications: NotificationsSearch(term: $term) {
                _id,
                title,
                body,
                senderUser {
                  name
                },
                timestamp,
                link,
                read
              }
            }
          `,
          variables: {
            term: this.search,
          },
        } ).then( res => {
          this.loading = false;
          this.searchNotifications = res.data.notifications;
          this.page = 1;
        } ).catch( () => {
          this.$alerts.error( 'Something went wrong!', 'Search went bad' );
        } );
        }, 1000 );
      }
    },
  },
  apollo: {
    notificationsPage: {
      query: gql`
        query NotificationsByPage($page: Int!){
          notificationsPage: NotificationsByPage(page: $page) {
            _id,
            title,
            body,
            senderUser {
              name
            },
            timestamp,
            link,
            read
          }
        }
      `,
      variables() {
        return {
          page: this.page - 1,
        };
      },
    },
    total: {
      query: gql`
        query {
          total: TotalNotifications
        }
      `,
    },
  },
  computed: {
    maxPage() {
      if ( !this.searchNotifications ) {
        return Math.ceil( this.total / this.notificationsPerPage );
      }

        return Math.ceil( this.searchNotifications.length / this.notificationsPerPage );

    },
    searchNotificationsSlice() {
      return this.searchNotifications.slice( 0 + ( ( this.page - 1 ) * 20 ), 20 + ( ( this.page - 1 ) * 20 ) );
    },
    senderAcronym() {
      return this.chosen.senderUser.name.split( " " ).map( i => i.charAt( 0 ) ).join( "" );
    },
    pureDate() {
      return this.chosen.timestamp.split( "T" )[0].split( "-" ).reverse().join( "/" );
    },
  },
  methods: {
    choose( notification ) {
      this.chosen = notification;
      this.$apollo.mutate( {
        mutation: gql`
          mutation ($id: ObjectID) {
            dismissNotification(id: $id)
          }
        `,
        variables: {
          id: notification._id,
        },
      } ).then( () => {
        notification.read = true;
      } );
    },
    checkPageInput() {
      const numbersRegex = /^[0-9]+$/;
      if( numbersRegex.test( this.page ) ) {
        if ( this.page < 1 ) {
          this.page = 1;
          this.$forceUpdate();
        } else if ( this.page > this.maxPage ) {
          this.page = this.maxPage;
          this.$forceUpdate();
        }
      } else {
        this.page = this.page.substring( 0,this.page.length - 1 );
        this.$forceUpdate();
      }
    },
    increment() {
      if( this.page < this.maxPage ) {
        this.page++;
        if ( !this.searchNotifications ) {
          this.$apollo.queries.notifications.refetch();
        }
      }
    },
    decrease() {
      if( this.page > 1 ) {
        this.page--;
        if ( !this.searchNotifications ) {
          this.$apollo.queries.notifications.refetch();
        }
      }
    },
    moment,
  },
  components: {
    EditCustomerModal,
    // FormInput
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .Avatar {
    display: inline-block;
    vertical-align: top;
    width: 18px;
    height: 18px;
    padding: 9px 10px 9px 8px;
    background: #f8b23f;
    border-radius: 100%;
  }

  .Button {
    margin-right: 5px;
  }

  .Table {
    &_Body {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .Search {
    padding-bottom: 5px;
  }

  .Notifications {
    &_Container {
      margin-top: 15px;
      // border-style: solid;
      // border-width: 1px;
      // border-radius: 4px;
      display: flex;
      flex-direction: row;
      height: 100%;

      a {
        color: black;
      }

      &_List {
        width: 35%;
        border-right: 1px solid $hugr-colours-grey;
        padding-right: 10px;

        &_Navigation {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-bottom: 10px;

          &_Page {
            display: inline;
            padding: 8px;

            &_Input {
              display: inline;
              width: 25px;
            }

            &_Max {
              display: inline;
            }
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        &_Unit {
          list-style-type: none;
          padding: 10px 10px 10px 0px;
          // border-bottom: 1px solid;
          border-left: 5px solid $hugr-colours-grey;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover, &:focus, &.active, &:focus-within {
            background: lighten($hugr-colours-grey, 10%);
          }

          &._read {

          }

          &._unread {
            border-left: 5px solid $hugr-colours-secondary;
          }

          &_Head {
            padding-left: 5px;
            font-weight: bold;
            font-size: 15px;
          }

          &_Body {
            padding-top: 2px;
            padding-left: 5px;
            p {
              margin:0;
            }
          }
        }
      }

      &_Content {
        width: 100%;
        height: 600px;
        display: flex;
        flex-direction: column;

        &_Body {
          position: fixed;
          width: 65%;

          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: stretch;

          padding: 0 20px;

          &_Details {
            > span {
              display: inline-block;
            }

            &_D {
              margin-left: 10px;
            }
          }

          &_Content {
            margin: 50px 0;
          }

          &_None {
            margin-top: 30%;
            text-align: center;
          }
        }
      }
    }
  }

  ._darkMode .Notifications {
    &_Container {
      &_List {
        &_Unit {
          &:hover, &:focus, &.active, &:focus-within {
            background: lighten($hugr-colours-primary, 10%);
          }
        }
      }
    }
  }
</style>
